export interface AIModel {
    model_provider: string;
    model_id: string;
    model_type: 'openai' | 'anthropic' | 'google' | 'chatgpt';

    model_rank: number;

    model_display_name: string;
    model_description: string;
    model_image: string | null;
    model_price: number;
}

export const openAIModels: AIModel[] = [
    {
        model_id: "openai/gpt-4o-mini",
        model_rank: 1,
        model_provider: 'openai',
        model_type: 'openai',
        model_image: '/frontend-assets/images/model-family/openAI.jpeg',

        model_display_name: "GPT-4o mini",
        model_description: "OpenAI, tác vụ dễ, nhanh và rẻ",
        model_price: 0.0156,
    },
    {
        model_id: "openai/gpt-4o",
        model_rank: 2,
        model_provider: 'openai',
        model_type: 'openai',
        model_image: '/frontend-assets/images/model-family/openAI.jpeg',

        model_display_name: "gpt-4o",
        model_description: "OpenAI, công việc phức tạp, logic tính toán",
        model_price: 0.4680,
    },
    {
        model_id: "openai/chatgpt-4o-latest",
        model_rank: 3,
        model_provider: 'openai',
        model_type: 'openai',
        model_image: '/frontend-assets/images/model-family/openAI.jpeg',

        model_display_name: "chatgpt-4o-latest",
        model_description: "OpenAI, công việc phức tạp, logic tính toán",
        model_price: 0.4680,
    },
    {
        model_id: "openai/o3-mini",
        model_rank: 4,
        model_provider: 'openai',
        model_type: 'openai',
        model_image: '/frontend-assets/images/model-family/openAI.jpeg',

        model_display_name: "GPT-o3 mini",
        model_description: "OpenAI, công việc phức tạp, logic tính toán",
        model_price: 0.3,
    },


    // RANK 3


];

export const anthropicAIModels: AIModel[] = [
    {
        model_id: "anthropic/claude-3-5-haiku",
        model_rank: 1,
        model_provider: "anthropic",
        model_type: "anthropic",
        model_image: '/frontend-assets/images/model-family/claude-level-3.jpeg',

        model_display_name: "Claude-haiku 3.5",
        model_description: "Anthorpic, tác vụ dễ, viết sáng tạo tốt ",
        model_price: 0.0156,
    },
    {
        model_id: "anthropic/claude-3-7-sonnet",
        model_rank: 3,
        model_provider: "anthropic",
        model_type: "anthropic",
        model_image: '/frontend-assets/images/model-family/claude-level-3.jpeg',

        model_display_name: "Claude-Sonet-3.7",
        model_description: "Anthorpic, công việc phức tạp, logic tính toán, lập trình",
        model_price: 0.7020,
    },
    {
        model_id: "anthropic/claude-3-7-sonnet-thinking",
        model_rank: 4,
        model_provider: "anthropic",
        model_type: "anthropic",
        model_image: '/frontend-assets/images/model-family/claude-level-3.jpeg',

        model_display_name: "Claude-Sonet-3.7 thinking",
        model_description: "Anthorpic, công việc phức tạp, logic tính toán, lập trình",
        model_price: 0.7020,
    },
];

export const googleAIModels: AIModel[] = [
    {
        model_id: "google/gemini-2.0-flash",
        model_rank: 1,
        model_provider: "google",
        model_type: "google",
        model_image: '/frontend-assets/images/model-family/gemini.jpeg',

        model_display_name: "Gemini-2.0 flash",
        model_description: "Google, tác vụ dễ, tốt cho dịch thuật",
        model_price: 0.0156,
    },
    {
        model_id: "google/gemini-2.5-pro-exp-03-25",
        model_rank: 4,
        model_provider: "google",
        model_type: "google",
        model_image: '/frontend-assets/images/model-family/gemini.jpeg',

        model_display_name: "Gemini-2.5 pro",
        model_description: "Google, công việc phức tạp, logic tính toán, dịch thuật",
        model_price: 0.4,
    },
    {
        model_id: "google/gemini-2.0-flash-thinking-exp",
        model_rank: 4,
        model_provider: "google",
        model_type: "google",
        model_image: '/frontend-assets/images/model-family/gemini.jpeg',

        model_display_name: "Gemini-2.0 flash-thinking-exp",
        model_description: "Google, công việc phức tạp, logic tính toán, dịch thuật",
        model_price: 0.02,
    },
    {
        model_id: "google/gemini-2.0-flash-exp-image-generation",
        model_rank: 1,
        model_provider: "google",
        model_type: "google",
        model_image: '/frontend-assets/images/model-family/gemini.jpeg',

        model_display_name: "Gemini-2.0 flash image generation",
        model_description: "Google, model tạo ảnh đa nhiệm",
        model_price: 0.02,
    },
];

export const deepseekAIModels: AIModel[] = [
    {
        model_id: "deepseek/deepseek-chat",
        model_rank: 3,
        model_provider: "deepseek",
        model_type: "chatgpt",
        model_image: '/frontend-assets/images/model-family/deepseek.png',

        model_display_name: "Deepseek V3-0324",
        model_description: "công việc phức tạp, logic tính toán (không đọc được ảnh)",
        model_price: 0.0156,
    },
    {
        model_id: "deepseek/deepseek-reasoner",
        model_rank: 4,
        model_provider: "deepseek",
        model_type: "chatgpt",
        model_image: '/frontend-assets/images/model-family/deepseek.png',

        model_display_name: "Deepseek Reasoner",
        model_description: "Private Server, Deepseek mô hình suy luận mới (không đọc được ảnh)",
        model_price: 0.05,
    },
]

export const alibabaAIModels: AIModel[] = [

    {
        model_id: "alibaba/qwen-vl-plus",
        model_rank: 1,
        model_provider: "alibaba",
        model_type: 'chatgpt',
        model_image: '/frontend-assets/images/model-family/qwen.jpeg',

        model_display_name: "Qwen VL plus",
        model_description: "Alibaba, công việc phức tạp, logic tính toán",
        model_price: 0.02,
    },
    {
        model_id: "alibaba/qwen-vl-max",
        model_rank: 2,
        model_provider: "alibaba",
        model_type: 'chatgpt',
        model_image: '/frontend-assets/images/model-family/qwen.jpeg',

        model_display_name: "Qwen VL Max",
        model_description: "Mô hình suy luận mới của Alibaba (tạm thời chưa đọc được ảnh)",
        model_price: 0.04,
    },
    {
        model_id: "alibaba/qwq-plus",
        model_rank: 4,
        model_provider: "alibaba",
        model_type: 'chatgpt',
        model_image: '/frontend-assets/images/model-family/qwen.jpeg',

        model_display_name: "Qwen QWQ Plus",
        model_description: "Alibaba, công việc phức tạp, logic tính toán (tạm thời chưa đọc được ảnh)",
        model_price: 0.03,
    },
]

export const volcengineAIModels: AIModel[] = [
    {
        model_id: "volcengine/doubao-vision-lite-32k-241015",
        model_rank: 1,
        model_provider: "volcengine",
        model_type: 'chatgpt',
        model_image: '/frontend-assets/images/model-family/doubao.png',

        model_display_name: "Doubao Vision Lite 1.5",
        model_description: "Volcengine, tác vụ dễ, tốc độ cao",
        model_price: 0.0156,
    },
    {
        model_id: "volcengine/doubao-1-5-vision-pro-32k-250115",
        model_rank: 2,
        model_provider: "volcengine",
        model_type: 'chatgpt',
        model_image: '/frontend-assets/images/model-family/doubao.png',

        model_display_name: "Doubao Vision pro 1.5",
        model_description: "Volcengine, công việc phức tạp, logic tính toán",
        model_price: 0.055,
    },
]

export const minimaxModels: AIModel[] = [
   
]

export const modelList: AIModel[] = [
    ...openAIModels,
    ...anthropicAIModels,
    ...googleAIModels,
    ...alibabaAIModels,
    ...volcengineAIModels,
    ...deepseekAIModels,
];


/**
 Magic Rank Title
 https://mushokutensei.fandom.com/wiki/Magic#Magic_Rank_Title
Rank	Title	Scale
1	Beginner / Elementary	Basic mana manipulation.
2	Intermediate	Advanced mana manipulation.
3	Advanced	Complex mana manipulation.Generally large area of effect.
4	Saintly / Holy	Large scale complex mana manipulation.Usually spells that affects the weather.
5	Kingly	Builds upon Saint - class Magic by increasing its effectiveness.
6	Imperial / Emperor	Further enhances the scale of King - class Magic
7	Divine / God	Continental scale magic.
 */


