import { Injectable, inject, signal } from '@angular/core';
import { SnackbarService, graphQLClient } from '@app/frontend-core';
import { AppMessageConversation, GetConversationsDocument, GetConversationsQueryVariables, NewConversationDocument, NewConversationMutationVariables, NewThreadDocument, NewThreadMutationVariables, ReadConversationDocument, RemoveConversationDocument, RemoveConversationMutationVariables, SaveMessageDocument, SaveMessageMutationVariables, UpdateConversationDocument, UpdateMessageContentDocument, UpdateMessageContentMutationVariables } from '@app/generated';
import { injectMutation, injectQuery, QueryClient } from '@tanstack/angular-query-experimental';
import { PeopleGear, SubscriptionGear } from 'frontend-state/src/people';
import { ModelGear } from './model.gear';
import { CharacterGear } from './character.gear';
import { aiStreaming, ChatMessageFull } from 'frontend-ui/src/chatter-dashboard/pages/ai-client';


@Injectable({ providedIn: 'root' })
export class ConversationGear {
    peopleGear = inject(PeopleGear);
    snackbarService = inject(SnackbarService);
    activeConversationId = signal<string | null | undefined>(null);
    queryClient = inject(QueryClient);
    currentConversationUserIds = signal<string[]>([]);
    subscriptionGear = inject(SubscriptionGear);
    modelGear = inject(ModelGear);
    characterGear = inject(CharacterGear);

    async setNewTitleOfConversationInStream(chatlog = 'user: hello\n model: Hi, how I can help?') {
        const activeConversation = this.getActiveConversation();
        const old = this.getLocalConversations() || [];
        const newData = [...old];
        if (activeConversation) {
            try {
                const streamingResult = await aiStreaming({
                    model_id: this.modelGear.defaultModelId,
                    subscription_id: this.subscriptionGear.activeSubscriptionId(),
                    systemPrompt: `Generate a concise, descriptive title (maximum 6-8 words) that captures the main topic of this conversation. The title should:
- Be in the same language as the conversation
- Use keywords from the actual conversation
- Not include any punctuation, quotation marks, or labels
- Start with a capital letter
- Focus on the core problem/question/topic discussed
- Use action words when applicable
Respond with ONLY the title text, nothing else.`,
                    max_tokens: 30,
                    messages: [
                        { role: 'user', text: chatlog, attachment_contents: [] },
                    ]
                }, (streamingMessage) => {
                    newData[old.indexOf(activeConversation)] = {
                        ...activeConversation,
                        conversationTitle: streamingMessage.text
                    };
                    this.setLocalConversations(newData);
                });

                const { appMessageUpdateConversation } = await graphQLClient.request(UpdateConversationDocument, {
                    conversationId: activeConversation.conversationId,
                    conversationTitle: streamingResult.text,
                    conversationUserIds: null,
                });
            } catch (error) {
                console.error(error);
                this.snackbarService.showError(error.message);
                if (error.startWith('wallet')) {
                    this.subscriptionGear.mySubscriptionQuery.refetch();
                }

            }
        }
    }

    getConversations = injectQuery(() => ({
        queryKey: ['memberConversations', {
            pConversationUserIds: this.currentConversationUserIds()
        } as GetConversationsQueryVariables],
        queryFn: async (queryKey) => {
            const { appMessageGetConversations } = await graphQLClient.request(GetConversationsDocument, {
                pConversationUserIds: this.currentConversationUserIds(),
                pSearch: '',
            });
            return appMessageGetConversations?.nodes || [];
            // return [...(appMessageGetConversations?.nodes || []), ...(appMessageGetConversations?.nodes || [])] || [];
        },
        enabled: !!this.peopleGear.userId(),
    }));


    setLocalConversations(newData: AppMessageConversation[]) {
        // Optimistically update to the new value
        this.queryClient.setQueryData(['memberConversations', {
            pConversationUserIds: this.currentConversationUserIds()
        } as GetConversationsQueryVariables], (old) => newData);
    }

    getLocalConversations(): AppMessageConversation[] {
        return this.queryClient.getQueryData(['memberConversations', {
            pConversationUserIds: this.currentConversationUserIds()
        } as GetConversationsQueryVariables]) ?? [];
    }

    getActiveConversation() {
        const appMessageConversations = this.getLocalConversations();
        const activeConversation = appMessageConversations?.find((conversation) => conversation?.conversationId === this.activeConversationId())
        return activeConversation;
    }

    getActiveConversationIndex() {
        const appMessageConversations = this.getLocalConversations();
        const activeIndex = appMessageConversations?.findIndex((conversation) => conversation?.conversationId === this.activeConversationId())
        return activeIndex;
    }

    queryGetMessages = injectQuery(() => ({
        queryKey: ['getMessages', {
            pConversationId: this.activeConversationId()!
        }],
        queryFn: async (queryKey) => {
            const pConversationId = this.activeConversationId()!;
            if (!pConversationId) return [];
            const { appMessageReadConversation } = await graphQLClient.request(ReadConversationDocument, {
                pConversationId,
                pSearch: '',
            });

            const messages = (appMessageReadConversation?.nodes ?? [])
                .map(chat => {
                    const tool_call = chat.tool_calls.find((t: any) => t.name === 'search_web');
                    const tool_result = chat.tool_results.find((t: any) => t.tool_call_id === tool_call.tool_call_id);



                    const search_web = {
                        tool_name: 'search_web',
                        tool_call: tool_call,
                        tool_result: tool_result
                    }

                    // console.log('search_web', search_web)
                    return {
                        role: chat.role,
                        messageCreatedAt: chat.message_created_at,
                        messageId: chat.message_id!,
                        attachment_contents: chat.attachment_contents ?? [],
                        metadata: chat.metadata,
                        senderUserId: chat.metadata.sender_user_id,
                        reasoning: chat.reasoning_contents[0]?.text,
                        text: chat.text_contents[0]?.text,
                        search_web: tool_call ? search_web : undefined
                    } as ChatMessageFull;
                });


            console.log('queryGetMessages! imcoming changes', appMessageReadConversation?.nodes, messages);
            this.updateAttachments(messages);
            return messages as ChatMessageFull[];
        },
        enabled: !!this.activeConversationId() && !!this.peopleGear.userId(),
    }));

    newMessageMutation = injectMutation(() => ({
        mutationFn: (newMessageData: SaveMessageMutationVariables) => {
            return graphQLClient.request(SaveMessageDocument, newMessageData);
        },
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);

        },
    }));
    
    updateMessageContentMutation = injectMutation(() => ({
        mutationFn: (newMessageData: UpdateMessageContentMutationVariables) => {
            return graphQLClient.request(UpdateMessageContentDocument, newMessageData);
        },
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);

        },
    }));

    async unlockMessageAttachment(key: string) {
        const response = await fetch(`/v1/api/message/signed-message-attachment?key=${key}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data.url;

    }

    async updateAttachments(messages: ChatMessageFull[]) {
        console.log('updateAttachments!!! messages', messages)
        const messPromises = (messages ?? []).map(async (mess) => {
            const attachmentPromises = (mess.attachment_contents ?? []).map(async (attachment) => {
                const url = await this.unlockMessageAttachment(attachment.key);
                return { ...attachment, url };
            });
            const attachment_contents = (await Promise.allSettled(attachmentPromises)).filter(r => r.status === 'fulfilled').map(r => r.value);

            return { ...mess, attachment_contents };
        });

        try {
            const messages = (await Promise.allSettled(messPromises)).filter(r => r.status === 'fulfilled').map(r => r.value);
            console.log('messages after load attachments', messages);
            this.setLocalMessages(messages);
        } catch (error) {
            console.error(error);
        }


    }

    setLocalMessages(messages: ChatMessageFull[]) {
        console.log('updateLocalMessages', messages)
        this.queryClient.setQueryData(['getMessages', {
            pConversationId: this.activeConversationId()!
        }], (old) => messages);
    }

    getLocalMessages() {
        const localMessages: ChatMessageFull[] = this.queryClient.getQueryData(['getMessages', {
            pConversationId: this.activeConversationId()!
        }]) ?? [];
        // console.log('localMessages', localMessages)
        return localMessages;
    }

    updateLocalMessagesItem(messageData: ChatMessageFull) {
        this.queryClient.setQueryData(['getMessages', {
            pConversationId: this.activeConversationId()!
        }], (old: ChatMessageFull[] | undefined) => {
            old = old ?? [];
            const messageIndex = old?.findIndex(msg => msg.messageId === messageData.messageId);
            if (messageIndex !== -1 || messageIndex === undefined) {
                const updatedMessages = [...old];
                updatedMessages[messageIndex] = messageData;
                return updatedMessages;
            } else {
                return [...old, messageData]
            }
        })

        const conversations = this.getLocalConversations();
        const updatedConversations = conversations.map(conversation => {
            if (conversation.conversationId === this.activeConversationId()) {
                return {
                    ...conversation,
                    conversationUpdatedAt: new Date().toISOString()
                };
            }
            return conversation;
        });

        this.setLocalConversations(updatedConversations.sort((c1, c2) => c1.conversationUpdatedAt > c2.conversationUpdatedAt ? -1 : 1));

        // console.log('after update getLocalConversations', this.getLocalConversations());

        return this.getLocalMessages();
    }

    newConversationMutation = injectMutation(() => ({
        mutationFn: (v: NewConversationMutationVariables) => graphQLClient.request(NewConversationDocument, v),
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);
            return data.appMessageNewConversation;

        },
        onSettled: async () => {
            return await this.queryClient.invalidateQueries({ queryKey: ['memberConversations'] })
        },
    }));

    removeConversation = injectMutation(() => ({
        mutationFn: (v: RemoveConversationMutationVariables) => graphQLClient.request(RemoveConversationDocument, v),
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);
            this.snackbarService.showSuccess('Đã xoá');

        },
        onSettled: async () => {
            await this.queryClient.invalidateQueries({ queryKey: ['memberConversations'] })
            return await this.queryClient.invalidateQueries({ queryKey: ['messagesByConversationId'] })
        },
    }));


    newThreadMutation = injectMutation(() => ({
        mutationFn: (v: NewThreadMutationVariables) => graphQLClient.request(NewThreadDocument, v),
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);

        },
    }));


    remainStatus = signal({
        level50: false,
        level10: false,
        level1: false,
        level0: false,
        currentPercent: 100,
    });

    checkRemainStatus(subscription: { subscriptionDailyValue: number; subscription_balance: number; }) {
        const remainPercent = subscription.subscription_balance / subscription.subscriptionDailyValue * 100;

        if (remainPercent < 50 && this.remainStatus().level50 === false) {
            this.snackbarService.showWarning('Dung lượng còn dưới 50%');
            this.remainStatus.set({
                currentPercent: remainPercent,
                level50: true,
                level10: false,
                level1: false,
                level0: false,
            });
        } else if (remainPercent < 10 && this.remainStatus().level10 === false) {
            this.snackbarService.showWarning('Dung lượng còn dưới 10%');
            this.remainStatus.set({
                currentPercent: remainPercent,
                level50: true,
                level10: true,
                level1: false,
                level0: false,
            });
        } else if (remainPercent < 1 && this.remainStatus().level1 === false) {
            this.snackbarService.showWarning('Dung lượng còn dưới 1%');
            this.remainStatus.set({
                currentPercent: remainPercent,
                level50: true,
                level10: true,
                level1: true,
                level0: false,
            });
        } else if (remainPercent < 0 && this.remainStatus().level0 === false) {
            this.snackbarService.showWarning('Dung lượng còn 0%');
            this.remainStatus.set({
                currentPercent: remainPercent,
                level50: true,
                level10: true,
                level1: true,
                level0: true,
            });
        } else {
            this.remainStatus.set({
                currentPercent: remainPercent,
                level50: true,
                level10: false,
                level1: false,
                level0: false,
            });
        }
    }


    // Modify base64ToFile to ensure proper file naming
    base64ToFile({ mimeType, data, name }: { mimeType: string, data: string, name?: string }) {
        // Remove data URL prefix if it exists
        const base64WithoutPrefix = data.includes('base64,')
            ? data.split('base64,')[1]
            : data;

        // Decode base64 string
        const binaryString = window.atob(base64WithoutPrefix);
        const bytes = new Uint8Array(binaryString.length);

        // Convert binary string to byte array
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Create Blob from byte array
        const blob = new Blob([bytes], { type: mimeType });

        // Create File object with a default name if none provided
        const fileName = name || `image_${Date.now()}.${mimeType.split('/')[1]}`;
        return new File([blob], fileName, { type: mimeType });
    }


    updateMessageAttachmentMutation = injectMutation(() => ({
        mutationFn: async ({ messageId, conversationId, attachments }: { messageId: string, conversationId: string, attachments: File[] }) => {
            if (!attachments || attachments.length === 0) {
                throw new Error('No file selected');
            }

            const formData = new FormData();
            formData.append('messageId', messageId);
            formData.append('conversationId', conversationId);

            // Debug logs
            console.log('Attachments before upload:', attachments);

            attachments.forEach((file, index) => {
                console.log(`Appending file ${index}:`, file);
                formData.append('attachments', file);
            });

            const response = await fetch('/v1/api/message/attachment', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Remove Content-Type header - let browser set it with boundary
                },
                body: formData,
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            return await response.json();
        },
        onError: (error) => {
            this.snackbarService.showError(error.message);
        },
        onSuccess: (data, variables, context) => {

        }
    }));

}