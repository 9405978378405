import { inject, Injectable } from '@angular/core';
// import { LinkOrRegisterGQL } from '@app/graphql-types';
import { catchError, delay, mergeMap, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { SocialLoginAddon } from '../addons/index.js';
import { SnackbarService } from '@app/frontend-core';
import { Data, Router } from '@angular/router';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { SocialUserAddon } from '../addons/social-user.addon.js';
import { injectMutation } from '@tanstack/angular-query-experimental';
import { graphQLClient } from '@app/frontend-core';
import { LinkOrRegisterDocument, LinkOrRegisterMutation, LinkOrRegisterMutationVariables } from '@app/generated'
import { PeopleGear } from './people.gear.js';
@Injectable({ providedIn: 'root' })
export class SocialLoginGear {
  peopleGear = inject(PeopleGear);
  socialLoginAddon = inject(SocialLoginAddon);
  snackbarService = inject(SnackbarService);
  router = inject(Router);
  dialogService = inject(DialogService);


  linkOrRegisterMutation = injectMutation(() => ({
    mutationFn: (v: LinkOrRegisterMutationVariables) => graphQLClient.request(LinkOrRegisterDocument, v),
    onSuccess: (data, variables, context) => {
      this.peopleGear.handleRegisterOfSocial(variables, data.linkOrRegister);
      this.peopleGear.myProfileQuery.refetch();
    }

  }))
  renderAndPrepareAsyncSocial(action: 'login' | 'register', provider: 'facebook' | 'apple' | 'google', callback: (response: LinkOrRegisterMutation) => void) {
    switch (provider) {
      // case 'apple':
      //   return this.socialLoginAddon.loadApple();
      case 'google':
        this.socialLoginAddon.googleRenderButton(action, (user: SocialUserAddon) => {
          this.loginRegisterSocial(action, provider, user, (response) => callback(response));
        })
        break;
      default:
        return console.warn('no default')
    }
  }

  private loginRegisterSocial(
    action: 'login' | 'register',
    provider: 'apple' | 'facebook' | 'google',
    userData: SocialUserAddon,
    callback: (response: LinkOrRegisterMutation) => void
  ) {
    console.log('social-login.gear: handling action:', action);
    if (action === 'register') {
      this.snackbarService.showWarning('Vui lòng đợi...')
    }
    if (!userData) {
      return console.warn('WARNING: goole provider is use, but no response data from: ',provider);
    }
    switch (provider) {
      // case 'facebook': {
      //   authAction$ = this.socialLoginAddon.facebookLogin();
      //   break;
      // }
      // case 'apple': {
      //   authAction$ = this.socialLoginAddon.appleLogin();
      //   break;
      // }
      case 'google': {
        this.linkOrRegisterMutation.mutate({
          linkToUserId: null,
          serviceId: userData.providerId,
          identifier: userData.identifier,
          profile: userData,
          authDetails: userData.providerData,
        }, {
          onSuccess: (data, variables, context) => {
            // close dialog or doing something
            callback?.(data);
          }
        });
        break;
      }
      default: {
        console.warn('I dont know this provider', provider);
        break;
      }
    }
  }

}
