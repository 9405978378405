import { computed, inject, Injectable, signal } from '@angular/core';
import { environment, graphQLClient, isBrowser, SnackbarService } from '@app/frontend-core';

import { injectMutation, injectQuery,  QueryClient } from '@tanstack/angular-query-experimental';
import { PeopleGear } from 'frontend-state/src/people';
import { Subject } from 'rxjs';
import { NodeManagerService, TreeNodeAction } from './node-manager.service';
import { AttachmentUploadPayload, DocumentUploadPayload, NodeLogItem, ObjectContent, DocumentObjectData, TreeNode } from 'configs/global';
import { jsonToBase64, blobToBase64 } from './file-data.service';
import { GetProjectsDocument, AppStorageProject, MergeObjectsDocument, AppStorageObject, GetProjectDocument, GetProjectObjectsDocument, NewProjectDocument, UpdateProjectDocument, GetPublicProjectsDocument } from '@app/generated';



@Injectable({ providedIn: 'root' })
export class ProjectGear {
    // client = inject(AppGraphqlClient);

    nodeManagerService = inject(NodeManagerService);
    peopleGear = inject(PeopleGear);
    currentProjectId = signal<string | null | undefined>(null);
    queryClient = inject(QueryClient);
    currentProject = computed(() => this.getProject.data());
    snackbarService = inject(SnackbarService);
    newFileEvent = new Subject<'up' | 'down' | 'in'>();

    getRootKey = computed(() => {
        if (!!this.currentProject()) {
            return `${this.currentProject()!.projectMode}/projects/${this.currentProjectId()}`;
        } else {
            throw new Error('Root key not found');
        }
    });

    updateLearnProjects(projectId: string) {
        const publicProjects = this.queryClient.getQueryData<AppStorageProject[]>(['appStoragePublicProjects']);
        if (publicProjects) {
            const updatedProjects = publicProjects.map(project => {
                if (project.projectId === projectId) {
                    return {
                        ...project,
                        updatedAt: Date.now()
                    };
                }
                return project;
            });
            this.queryClient.setQueryData(['appStoragePublicProjects'], updatedProjects);
        }

    }

    getLearnProjects = injectQuery(() => ({
        queryKey: ['appStoragePublicProjects'],
        queryFn: async ({ queryKey }) => {
            const { appStorageProjects } = await graphQLClient.request(GetPublicProjectsDocument);
            return appStorageProjects?.nodes || [];
        },
    }));

    getProjects = injectQuery(() => ({
        queryKey: ['appStorageProjects'],
        queryFn: async ({ queryKey }) => {
            const { appStorageGetProjects } = await graphQLClient.request(GetProjectsDocument);

            if (appStorageGetProjects?.nodes && appStorageGetProjects?.nodes.length > 0) {
                this.setDefaultProject(appStorageGetProjects?.nodes[0]);
            }
            return appStorageGetProjects?.nodes || [];

        },
        enabled: !!this.peopleGear.userId() && !!this.peopleGear.userId(),
    }));

    getProject = injectQuery(() => ({
        queryKey: ['appStorageProject', this.currentProjectId()],
        queryFn: async ({ queryKey }) => {
            const { appStorageProject } = await graphQLClient.request(GetProjectDocument, {
                projectId: queryKey[1]!,
            });

            return appStorageProject
        },
        enabled: !!this.peopleGear.userId() && !!this.currentProjectId(),
    }));

    newProjectMutation = injectMutation(() => ({
        mutationKey: ['appStorageNewProject'],
        mutationFn: async ({ pProjectName, pProjectMode }: { pProjectName: string, pProjectMode: 'public' | 'private' }) => {
            const { appStorageNewProject } = await graphQLClient.request(NewProjectDocument, {
                pProjectName,
                pProjectMode,
            });
            return appStorageNewProject?.result;
        },
        onSuccess: (data, variables, context) => {
            this.getProjects.refetch();
        },
        onError: (error, variables, context) => {

        },
        enabled: !!this.peopleGear.userId() && !!this.currentProjectId(),
    }));

    updateProjectMutation = injectMutation(() => ({
        mutationKey: ['appStorageUpdateProject'],
        mutationFn: async ({ pProjectId, pProjectInput }: {
            pProjectId: string, pProjectInput: {
                project_name?: string, project_mode?: string, home_oject_id?: string
            }
        }) => {
            console.warn('changing project mode', this.currentProject()?.projectMode, pProjectInput);

            const { appStorageUpdateProject } = await graphQLClient.request(UpdateProjectDocument, { pProjectId, pProjectInput });

            const isChangingMode = pProjectInput.project_mode && this.currentProject()?.projectMode !== pProjectInput.project_mode;

            if (isChangingMode && pProjectInput.project_mode) {
                this.updateProjectModeStorageMutation.mutate({
                    projectId: pProjectId,
                    newMode: pProjectInput.project_mode
                })
            }

            return appStorageUpdateProject;
        },
        onSuccess: (data, variables, context) => {
            this.getProjects.refetch();
            this.getProject.refetch();
        },
        onError: (error, variables, context) => {

        }
    }));

    updateProjectModeStorageMutation = injectMutation(() => ({
        mutationKey: ['updateProjectModeStorageMutation'],
        mutationFn: async ({ projectId, newMode }: { projectId: string, newMode: string }) => {
            const response = await fetch(`/v1/api/project`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    project_id: projectId,
                    action: 'changeMode',
                    newMode: newMode
                })
            });
            const updateProjectModeStorageMutation = await response.json();
            return updateProjectModeStorageMutation;
        },
        onSuccess: (data, variables, context) => {
            this.getProjects.refetch();
        },
        onError: (error, variables, context) => {

        }
    }));

    removeProjectMutation = injectMutation(() => ({
        mutationKey: ['appStorageRemoveProject'],
        mutationFn: async ({ project }: { project: AppStorageProject }) => {
            console.log('doing on project', project)
            const response = await fetch(`/v1/api/project?projectId=${project.projectId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json'
                },

            });
            if (response.ok) {
                const appStorageRemoveProject = await response.json();
                return appStorageRemoveProject?.result;
            } else {
                const errorData = await response.json();
                this.snackbarService.showError(errorData.message);
            }

        },
        onSuccess: (data, variables, context) => {
            this.getProjects.refetch();
        },
        onError: (error, variables, context) => {
            console.log('onError?', error);
        },
    }));

    makeLogsUnique(logs: { node: TreeNode, action: string, timestamp: number }[]) {
        return Array.from(
            new Map(
                [...logs
                    .filter(log => log.node.objectId !== this.nodeManagerService.ROOT_ID)
                    .sort((a, b) => a.timestamp - b.timestamp)]
                    .map(log => [log.node.objectId, log])
            )
                .values()
        );
    }

    relocateNodeOnServer() {
        this.mergeObjectsMutation.mutate({
            logs: this.nodeManagerService.logs(),
        });
    }

    updateNodeOnServer(objectId: string, objectMedata: { name: string }) {
        this.nodeManagerService.updateNode(objectId, {
            name: objectMedata.name,
            editing: false,
        });

        this.nodeManagerService.logs.update(logs => [
            ...logs,
            {
                node: this.nodeManagerService.nodeMap().get(objectId)!,
                action: TreeNodeAction.UPDATE,
                timestamp: Date.now()
            }
        ]);

        this.mergeObjectsMutation.mutate({
            logs: this.nodeManagerService.logs(),
        })
    }

    addNodeOnServer({ objectId, name, location }: { objectId: string, name: string, location: 'in' | 'up' | 'down' }, targetNode: TreeNode) {
        if (this.currentProjectId() !== targetNode.projectId) {
            this.snackbarService.showError('File đang tạo khác project Id');
            throw new Error('add node missmatch projectId');
        }
        const node = this.nodeManagerService.createNode({
            objectId,
            name,
            location,
            targetNode,
            userId: this.peopleGear.userId()!,
            projectId: this.currentProjectId()!,
        });
        console.log('new node', node);
        this.nodeManagerService.addNode(node);

        this.nodeManagerService.logs.update(log => [...log, { node, action: TreeNodeAction.ADD, timestamp: Date.now() }]);

        this.mergeObjectsMutation.mutate({
            logs: this.nodeManagerService.logs(),
        }, {
            onSuccess: (data, variables, context) => {
                this.getProjectObjects.refetch();
                this.getProjectObjectsRecent.refetch();
            }
        })
        return node;
    }

    mergeObjectsMutation = injectMutation(() => ({
        mutationKey: ['mergeObjects'],
        mutationFn: async ({ logs }: { logs: NodeLogItem[] }) => {
            const uniqueLogs = this.makeLogsUnique(logs);
            const { appStorageMergeObjects } = await graphQLClient.request(MergeObjectsDocument, {
                pProjectId: this.currentProjectId()!,
                logs: uniqueLogs
            });
            return appStorageMergeObjects?.result;
        },
        onSuccess: (data, variables, context) => {
            this.nodeManagerService.clearLogs();

        },
        onError: (error, variables, context) => {
            this.snackbarService.showError(error.message)
        },
        enabled: () => !this.mergeObjectsMutation.isPending,
    }));



    createAttachment({ attachmentId, contentType, objectId }: { objectId: string, attachmentId: string, contentType: string }) {
        const rootKey = this.getRootKey();

        return {
            objectId: objectId,
            attachmentId,
            contentType: contentType,
            name: attachmentId,
            key: `${rootKey}/attachments/${attachmentId}`,
            version: 'default',
            metadata: {},
        }
    }

    createStorageObject({ objectId, name }: { objectId: string, name: string }) {
        const rootKey = this.getRootKey();
        return {
            objectId: objectId,
            contentType: 'application/json',
            name: name,
            key: `${rootKey}/documents/${objectId}`,
            version: 'default',
            metadata: {},
        }
    }



    updateFileMutation = injectMutation(() => ({
        mutationKey: ['appStorageUpdateFile'],
        mutationFn: async ({ objectData, objectContent }: {
            objectData: DocumentObjectData, objectContent: ObjectContent
        }) => {
            const response = await fetch('/v1/api/project/file', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    project_id: this.currentProjectId(),
                    documentObjectData: objectData,
                    objectContent,

                } as DocumentUploadPayload),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;

        },
        onError: (error) => {
            this.snackbarService.showError(error.message);
        },
        onSuccess: (data, variables, context) => {

        }
    }));

    updateAttachmentMutation = injectMutation(() => ({
        mutationKey: ['appStorageUpdateAttachment'],
        mutationFn: async ({ objectData, fileContentImage }: {
            objectData: DocumentObjectData, fileContentImage?: Blob
        }) => {

            const response = await fetch('/v1/api/project/attachment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    project_id: this.currentProjectId(),
                    documentObjectData: objectData,
                    fileContentImage: fileContentImage ? await blobToBase64(fileContentImage) : undefined,
                } as AttachmentUploadPayload),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;
        },
        onError: (error) => {
            this.snackbarService.showError(error.message);
        },
        onSuccess: (data, variables, context) => {
            if (!data.error) {
                this.getProjectObjects.refetch();
            }
        }
    }));

    deleteFileMutation = injectMutation(() => ({
        mutationKey: ['appStorageDeleteFile'],
        mutationFn: async ({ logs }: { logs: NodeLogItem[] }) => {
            const response = await fetch(`/v1/api/project/file`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    project_id: this.currentProjectId(),
                    objectIds: this.makeLogsUnique(logs)
                        .filter(log => log.action === 'delete')
                        .map(log => `projects/${this.currentProjectId()}/documents/${log.node.objectId}`)
                })
            });
            const result = await response.json();
            return result;
        },
        onSuccess: (data, variables, context) => {
            if (!data.error) {
                this.getProjectObjects.refetch();
                this.getProjectObjectsRecent.refetch();
            }
        }
    }));

    setDefaultProject(project?: { projectId: string } | null) {
        if (project) {
            this.currentProjectId.set(project.projectId);
        }
    }

    // createUserProject() {
    //     this.newProjectMutation.mutate({ pProjectName: '', pProjectMode: 'private' }, {
    //         onSuccess: (data, variables, context) => {
    //             console.log('data', data);
    //             if (data) {
    //                 this.getProjects.refetch();
    //             }
    //         },
    //     });
    // }

    getObjectAtClient = (objectId: string) => {
        const currentObjects = this.queryClient.getQueryData<AppStorageObject[]>(['appStorageObjects', {
            pProjectId: this.currentProjectId(),
        }]) || [];

        return currentObjects.find(obj => obj.objectId === objectId) || null;
    };

    async fetchObjects(pProjectId: string | null | undefined, pOptions = {}) {
        if (!pProjectId) return [];
        try {
            const { appStorageGetObjects } = await graphQLClient.request(GetProjectObjectsDocument, {
                pProjectId: pProjectId,
                pOptions: pOptions,
            });
            let objects = appStorageGetObjects?.nodes.filter(node => !!node) || [];
            return objects;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    getProjectObjects = injectQuery(() => ({
        queryKey: ['getProjectObjects', {
            pProjectId: this.currentProjectId(),
        }],
        queryFn: async (queryKey) => {
            let objects = await this.fetchObjects(this.currentProjectId()!);
            this.nodeManagerService.dataToTreeNodes(this.currentProjectId()!, objects);
            if (objects.length) {
                this.checkObjectHome(objects);
            }
            return objects;
        },
        enabled: !!this.peopleGear.userId() && !!this.currentProject(),
    }));

    getProjectObjectsRecent = injectQuery(() => ({
        queryKey: ['getProjectObjectsRecent', {
            pProjectId: this.currentProjectId(),
        }],
        queryFn: async (queryKey) => {
            let objects = await this.fetchObjects(this.currentProjectId()!, { order_by: 'updated_at', limit: 5 })
            return objects;
        },
        enabled: !!this.peopleGear.userId() && !!this.currentProjectId(),

    }));


    async checkObjectHome(objects: Partial<AppStorageObject>[]) {
        const findFirstItemAsHome = () => {
            return objects.filter(object => !!object).find(object =>
                object?.path?.split('/').length === 2 && object.position === 0
            );
        };
        const currentProject = this.currentProject();
        const homeObjectId = currentProject?.homeObjectId;

        if (!homeObjectId) {
            console.log('homeObjectId not init!', homeObjectId, this.getProject.data());
            if (objects.length > 0) {
                const firstHomeObject = findFirstItemAsHome();
                if (firstHomeObject) {
                    this.updateHomeObjectId(firstHomeObject.objectId);
                }
            }
        } else {
            // console.log('homeObjectId already exist!', homeObjectId, this.getProject.data());
            const currentHomeObjectExists = objects.some(object =>
                object.objectId === homeObjectId
            );

            if (!currentHomeObjectExists) {
                const firstHomeObject = findFirstItemAsHome();
                if (firstHomeObject) {
                    this.updateHomeObjectId(firstHomeObject.objectId);
                }
            }
        }
    }


    async updateHomeObjectId(objectId?: string | null) {
        console.log('updateHomeObjectId: ', objectId);
        const { appStorageUpdateProject } = await graphQLClient.request(UpdateProjectDocument, {
            pProjectId: this.currentProjectId()!,
            pProjectInput: {
                home_object_id: objectId,
            }
        });
        this.getProject.refetch();
    }

}
