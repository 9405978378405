import { DOCUMENT } from '@angular/common';
import { Injectable, afterNextRender, inject } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ThemeService {
    document = inject(DOCUMENT);
    defaultLight = 'light';
    defaultDark = 'dark';
    themeList = [
        'auto',
        'light',
        'dark',
        // 'retro',
        // 'cyberpunk',
        // 'valentine',
        // 'aqua',
    ];

    constructor() {
       
     }


    themeStart(currentTheme = localStorage.getItem('theme')): string {
        if (currentTheme && currentTheme.length > 0) {
            if (currentTheme === 'auto') {
                const date = new Date();
                const millisecondsInAnHour = 60 * 60 * 1000;
                const sunrise = 6 * millisecondsInAnHour; // 6:00 AM in milliseconds
                const sunset = 18 * millisecondsInAnHour; // 6:00 PM in milliseconds
                const currentTime = date.getHours() * millisecondsInAnHour + date.getMinutes() * 60 * 1000 + date.getSeconds() * 1000;
                const isDay = currentTime > sunrise && currentTime < sunset;
                const theme = isDay ? this.defaultLight : this.defaultDark;
                this.document.documentElement.setAttribute('data-theme', theme);
                return theme;
            } else if (this.themeList.includes(currentTheme)) {
                this.document.documentElement.setAttribute('data-theme', currentTheme);
                return currentTheme;
            }
        }

        // Handle cases where theme is '', null, undefined, or not in themeList
        console.warn('Invalid theme detected, defaulting to auto');
        localStorage.removeItem('theme');
        return this.themeStart('auto');
    }
    
}