import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { toggleElementOnClick, clickInsideBlur } from '@app/frontend-core';
import { LandingFooterComponent } from './components/landing-footer/landing-footer.component';
import { ChevronDown, LucideAngularModule } from 'lucide-angular';
import { SideDrawerComponent } from 'frontend-ui/src/layout/side-drawer.component';

interface PathLinkChild {
  title: string;
  path?: string;
  fragment?: string;
  href?: string;
  class?: string;
}
interface PathLink extends PathLinkChild {
  children?: PathLinkChild[];
}

@Component({
    selector: 'buyer-layout',
    imports: [RouterOutlet, RouterLink, CommonModule, LandingFooterComponent, LucideAngularModule, SideDrawerComponent],
    template: `
    <!-- <ng-container [ngTemplateOutlet]="mainTemplate"></ng-container> -->
         <!-- <app-landing-footer class="w-full"></app-landing-footer> -->
<app-side-drawer>
     <router-outlet></router-outlet>
</app-side-drawer>


  `,
    styles: [
        `
      :host {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class buyerWebSharedLayout {
  toggleElementOnClick = toggleElementOnClick;
  clickInsideBlur = clickInsideBlur;

  icons = { ChevronDown };
  navigationLinks: PathLink[] = [
    { path: '/blog', title: 'Blog' },
    { path: '/pricing', title: 'Giá tiền' },

    // {
    //   path: '',
    //   title: 'Tài nguyên',
    //   children: [

    //     { href: 'https://www.facebook.com/losaai/', title: 'Fanpage Facebook' },
    //     { path: '/roadmap', title: 'Lộ trình phát triển' },
    //     { path: '/changelog', title: 'Nhật ký thay đổi' },
    //   ],
    // },
  ];
  constructor() { }
}
