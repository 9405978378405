import { Injectable, computed, inject, signal } from '@angular/core';
import { graphQLClient } from '@app/frontend-core';
import { AICharacterInformation, AppPeopleUser, ViewUserDocument, informationOfCharacters } from '@app/generated';
import { injectMutation, injectQuery, QueryClient } from '@tanstack/angular-query-experimental';
import slugify from 'slugify';
@Injectable({ providedIn: 'root' })
export class CharacterGear {
    queryClient = inject(QueryClient);

    currentCharacterId = signal<string | null>(null);


    informationOfCharacters = informationOfCharacters;
    characterFilterResult = signal<AICharacterInformation[]>([]);

    constructor() {

    }

    quickSearch = injectMutation(() => ({
        mutationKey: ['quickSearch', this.currentCharacterId()],
        mutationFn: async ({ query }: { query: string }) => {
            const characters = informationOfCharacters
                .filter(c => slugify(c.characterDisplayName, { lower: true }).includes(slugify(query, { lower: true })));
            this.characterFilterResult.set(characters);
        },
    }));

    userSuggestions = computed(() => {
        const items = informationOfCharacters
            .find(c => c.characterId === this.currentCharacterId())?.userSuggestions;

        if (!items) return [];

        // Create shuffled copy of array
        const shuffled = [...items]
            .sort(() => Math.random() - 0.5);

        return shuffled;
    });

    updateCharacterId(characterId?: string | null) {
        if (!!!characterId?.length) {
            characterId = informationOfCharacters[0].characterId;
        }
        this.currentCharacterId.set(characterId);
        return characterId;
    }

    characterUserQuery = injectQuery(() => ({
        queryKey: ['characterUser', this.currentCharacterId()],
        queryFn: async ({ queryKey }) => {
            const fetchCharacterUser = async (characterId: string | null) => {
                if (!characterId) throw new Error('no character id');
                const { appPeopleUsers } = await graphQLClient.request(ViewUserDocument, { userId: characterId });
                return appPeopleUsers?.nodes.length ? appPeopleUsers?.nodes[0] : null;
            };
            const characterUser = await fetchCharacterUser(queryKey[1]);
            return characterUser;
        },
    }));

}