import { computed, inject, Injectable, signal } from '@angular/core';
import { checkAccessTokenExpired, isBrowser, SnackbarService } from '@app/frontend-core';
import { DialogService } from '@ngneat/dialog';
import {
  LoginDocument,
  LoginMutationVariables,
  LogoutDocument,
  MyProfileDocument,
  RegisterDocument,
  RegisterMutationVariables,
  VerifyEmailDocument,
  VerifyEmailMutationVariables,
  LastActiveDocument,
  MyProfileQuery,
  ViewUserDocument,
  LinkOrRegisterMutationVariables,
  LinkOrRegisterMutation,
  LoginMutation
} from '@app/generated';
import { injectMutation, injectQuery,  QueryClient } from '@tanstack/angular-query-experimental';
import { graphQLClient, PlausibleService } from '@app/frontend-core';

@Injectable({ providedIn: 'root' })
export class PeopleGear {
  plausibleService = inject(PlausibleService);
  snackbarService = inject(SnackbarService);
  dialogService = inject(DialogService);
  queryClient = inject(QueryClient);
  
  lastActive?: number;
  // myProfile = qQuery(MyProfileDocument);
 

  viewUserQuery = (userId: string) => injectQuery(() => ({
    queryKey: ['viewUser', userId],
    queryFn: async ({ queryKey }) => {
    const { appPeopleUsers } = await graphQLClient.request(
        ViewUserDocument, { userId: queryKey[1] }
      );
      return appPeopleUsers;
    }
  }));

  // Initialize myProfile with a loading state
  userId = computed(() => this.myProfile() ? this.myProfile()!.userId : undefined);
  
  myProfile = signal<MyProfileQuery['appPeopleMyProfile'] | null | undefined>(undefined);

  myProfileQuery = injectQuery(() => ({
    queryKey: ['appPeopleMyProfile'],
    queryFn: async ({ queryKey }) => {
      let cachedMyProfile = this.queryClient.getQueryData(['appPeopleMyProfile']) as MyProfileQuery;
      const debug = false;

      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          debug && console.log('No access token found, logging out');
          this.handleLogout(false);
          this.myProfile.set(null);
          return null;
        }

        // If we have cached data and the token isn't expired, return the cached data
        if (cachedMyProfile?.appPeopleMyProfile) {
          const isTokenValid = await checkAccessTokenExpired();
          if (isTokenValid) {
            debug && console.log('Using cached appPeopleMyProfile data');
            this.myProfile.set(cachedMyProfile.appPeopleMyProfile);
            return cachedMyProfile.appPeopleMyProfile;
          }
        }

        // Fetch new data
        debug && console.log('Fetching new appPeopleMyProfile data');
        const response = await graphQLClient.request(MyProfileDocument);

        // Update last active status
        if (!this.lastActive || Date.now() - this.lastActive > 60 * 1000) {
          this.lastActiveMutation.mutate();
          this.lastActive = Date.now();
        }

        if (!response.appPeopleMyProfile) {
          debug && console.log('No profile data, logging out');
          this.handleLogout(false);
          this.myProfile.set(null);
          return null;
        }

        debug && console.log('saving appPeopleMyProfile data to myProfile variable');
        this.myProfile.set(response.appPeopleMyProfile);
        return response.appPeopleMyProfile;

      } catch (error) {
        debug && console.error('Error fetching profile:', error);

        // Check if the error is due to token expiration
        if (error) {
          debug && console.log('Token expired, attempting to refresh');
          const refreshSuccess = await checkAccessTokenExpired();
          if (refreshSuccess) {
            // Retry the query after token refresh
            this.myProfileQuery.refetch();
          } else {
            this.handleLogout(false);
          }
        }

        this.snackbarService.showError(error.message);
        this.myProfile.set(null);
        throw error;
      }
    },
    refetchOnWindowFocus: true,
    enabled: isBrowser(),
    retry: (failureCount, error) => {
      // Only retry if it's not an authentication error
      if (error) {
        return false;
      }
      return failureCount < 3;
    },
  }));



  logoutMutation = injectMutation(() => ({
    mutationFn: async () => {
      const response = await graphQLClient.request(LogoutDocument);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      return response;
    },
    onSuccess: (data, variables, context) => {
      this.myProfile.set(null);
      this.handleLogout();
    }
  }));


  lastActiveMutation = injectMutation(() => ({
    mutationFn: () => graphQLClient.request(LastActiveDocument),
    onSuccess: (data, variables, context) => {
    }
  }))

  loginEmailMutation = injectMutation(() => ({
    mutationFn: async (v: LoginMutationVariables) => {
      const response = await graphQLClient.request(LoginDocument, v);
      if (response.login?.accessToken && response.login?.refreshToken) {
        localStorage.setItem('accessToken', response.login.accessToken);
        localStorage.setItem('refreshToken', response.login.refreshToken);
      }
      return response;
    },
    onError: (error, variables, context) => {
      this.handleError(error);
    },
    onSuccess: (data, variables, context) => {
      this.handleLogin(data.login);
      console.log('login by email data:', data.login);
      this.myProfileQuery.refetch();
    },
  }));


  registerEmailMutation = injectMutation(() => ({
    mutationFn: (v: RegisterMutationVariables) => graphQLClient.request(RegisterDocument, v),
    onError: (error, variables, context) => {
      this.handleError(error);
    },
    onSuccess: (data, variables, context) => {
      this.snackbarService.showSuccess(`Đăng ký thành công, vui lòng kích hoạt email ${variables.email}`);
      this.plausibleService.trackEvent('Signup-email', { props: { provider: 'email', status: 'pending' } });
    },
  }));

  verifyEmailMutation = injectMutation(() => ({
    mutationFn: (v: VerifyEmailMutationVariables) => graphQLClient.request(VerifyEmailDocument, v),
    onError: (error, variables, context) => {
      this.handleError(error);
    },
    onSuccess: (data, variables, context) => {
      if (data.appPeopleVerifyEmail?.result) {
        this.snackbarService.showSuccess('Xác minh thành công, hãy đăng nhập lại nhé!', { duration: 3000 });
        this.plausibleService.trackEvent('Verify-email', { props: { provider: 'email', status: 'verified' } });
      } else {
        this.snackbarService.showError();
      }
    },
  }))

  handleError(error: Error) {
    console.warn('handleError people:', error);
    const code = error.message.split(':')[0];
    switch (code) {
      case 'WEAKP':
        this.snackbarService.showError('Mật khẩu quá yếu, cần lớn hơn 8 ký tự');
        break;
      case 'LOCKD':
        this.snackbarService.showError('Quá nhiều lần reset, thử lại sau 6 tiếng');
        break;
      case 'CREDS':
        this.snackbarService.showError('Tên đăng nhập / mật khẩu không đúng');
        break;
      case 'TAKEN':
        this.snackbarService.showError('Tài khoản khác đã liên kết với hồ sơ này');
        break;
      case 'EMTKN':
        this.snackbarService.showError('Tài khoản khác đã liên kết với email này');
        break;
      case 'RFTE':
        this.snackbarService.showError('Xác thực hết hạn, đăng nhập lại bạn nhé!');
        break;
      default:
        this.snackbarService.showError('Có lỗi khi đăng nhập đăng ký');
        break;
    }
    // NOTE: RESET LOCALSTORAGE TO MAKE SURE EVERYONE GETS LOGGED OUT
    console.warn("\n\n\n --- RESET --- \n\n\n, I'm doing localStorage clear because of error")
    // localStorage.clear();
  }

  handleRegisterOfSocial(variables: LinkOrRegisterMutationVariables, data?: LinkOrRegisterMutation['linkOrRegister'] | null,) {
    console.log('handleRegister', data, variables);
    if (data && data.accessToken && data.refreshToken) {
      this.handleLogin(data);
      if (data.note.isNewUser && variables.profile.provider) {
        this.plausibleService.trackEvent('Signup', { props: { provider: variables.profile.provider } });
      }
    } else {
      this.snackbarService.showError(`Lỗi không đọc được xác thực`);
    }
  }



  handleLogin(loginPayload?: LinkOrRegisterMutation['linkOrRegister'] | LoginMutation['login']) {
    console.log('handleLogin', loginPayload);
    if (loginPayload) {
      localStorage.setItem('accessToken', loginPayload.accessToken);
      localStorage.setItem('refreshToken', loginPayload.refreshToken);
      localStorage.setItem('sessionId', loginPayload.sessionId);
    }
    this.snackbarService.showSuccess(`Chào mừng bạn`);
    //  this.router.navigateByUrl('/');  despricate, handle at login page
  }



  handleLogout(notify = true) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('sessionId');
    if (notify) {
      this.snackbarService.showSuccess('Tạm biệt');
    }
  }

}
