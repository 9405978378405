import { Component, inject, computed, input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarState } from './navbar.state';
import { LucideAngularModule, Menu } from 'lucide-angular';
import { CollapseMenu, CollapseMenusComponent } from '../components/collapse-menu.component';
import { MainSidebarComponent } from './main/main-sidebar.component';
import { DisplayOnScrollDirective } from '../directives/display-on-scroll.directive';

@Component({
  selector: 'app-side-drawer',
  imports: [CommonModule, LucideAngularModule, CollapseMenusComponent, MainSidebarComponent, DisplayOnScrollDirective],
  styles: [`
   :host {
    display: flex;
    width: 100%;
    height: 100%;
   }
  `],
  template: `
<div class="drawer" [ngClass]="navbarState.chatLeftBarsMenu() ? 'md:drawer-open' : ''">
  <input title="drawer" id="left-chat-drawer" type="checkbox" class="drawer-toggle"
    [attr.checked]="navbarState.chatLeftBarsMenu() ? '' : null" />
  <div class="drawer-content flex flex-col min-h-svh relative w-full">

  <div class="sticky left-0 top-0 z-[1]">
    <div class="py-4 px-2 absolute left-0 top-0">
        <button (click)="navbarState.toggleLeftBarsMenu()" class="btn text-xl btn-ghost pb-2">
        <lucide-icon [name]="icons.Menu" class="w-6 h-6"></lucide-icon>
      </button>
    </div>
    <div [displayOnScroll]  class="navbar bg-base-300">
      <div class="navbar-start flex items-start">
        <button (click)="navbarState.toggleLeftBarsMenu()" class="btn text-xl btn-ghost">
          <lucide-icon [name]="icons.Menu" class="w-6 h-6"></lucide-icon>
        </button>
      </div>
      <div  class="navbar-center flex items-center">
        @if(navbarCenter(); as navbarItem) {
          <ng-container [ngTemplateOutlet]="navbarItem"></ng-container>
        }
      </div>
      <div class="navbar-end flex items-end">

      </div>
    </div>
  </div>

    

    @if(secondNavbar(); as secondNavbar){
      <ng-container [ngTemplateOutlet]="secondNavbar"></ng-container>
    }
    
    <div class="relative flex-1 flex flex-col justify-center items-center w-full h-full  max-w-full md:max-w-screen-md lg:max-w-screen-lg mx-auto">
      <ng-content></ng-content>
    </div>
  </div>


  <div class="drawer-side z-10">
      <label (click)="navbarState.chatLeftBarsMenu.set(false)" aria-label="close sidebar" class="drawer-overlay"></label>
      <div class="flex flex-col bg-base-300 h-full w-[85vw] md:w-48 lg:w-64 xl:w-72 max-h-dvh relative">
        <app-collapse-menus class="flex-grow min-w-0 overflow-auto" [collapseMenus]="collapseMenus()"></app-collapse-menus>
        <app-main-sidebar class="h-auto" [attr.data-name]="'bottomSidebar'" [dataMenus]="navbarState.bottomSidebar()"></app-main-sidebar>
      </div>
  </div>
</div>

<!-- <div class="navbar p-0 w-full min-h-8 bg-base-100"> -->
<!-- <ng-template #defaultNavbarTemplate>
<div class="fixed top-0 left-0 navbar p-0 w-0 h-0">
  <div class="navbar-start flex items-start">
    <button (click)="navbarState.toggleLeftBarsMenu()" class="btn text-xl btn-ghost">
      <lucide-icon [name]="icons.Menu" class="w-6 h-6"></lucide-icon>
    </button>
  </div>
  <div class="navbar-center flex items-center">
    
  </div>
</div>

</ng-template> -->
  `
})
export class SideDrawerComponent {
  icons = { Menu }
  navbarState = inject(NavbarState);
  navbarCenter = input<TemplateRef<any>>();
  secondNavbar = input<TemplateRef<any>>();

  collapseMenus = input<CollapseMenu[]>([
    {
      id: 'main-sidebar',
      name: '',
      opened: true,
      component: MainSidebarComponent,
      actions: [],
    },
  ]);
}