import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, OnInit, afterNextRender, inject, signal } from '@angular/core';
import { ThemeService } from 'frontend-core/src/services/theme.service';

@Component({
    imports: [CommonModule],
    selector: 'app-change-theme',
    template: `
    <div class="dropdown">
        <div tabindex="0" role="button" class="btn  m-1">
            Giao diện {{ currentTheme() }}
            <svg
            width="12px"
            height="12px"
            class="inline-block h-2 w-2 fill-current opacity-60"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2048 2048">
            <path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z"></path>
            </svg>
        </div>
        <ul tabindex="0" class="dropdown-content bg-base-300 text-base-content rounded-box z-[1] w-52 p-2 shadow-2xl">
            @for (themeName of themeService.themeList; track $index) {
                <li name="theme-dropdown" [ngClass]="currentTheme() === themeName ? 'bg-accent text-accent-content' : ''"
                    class="btn btn-sm btn-block btn-ghost justify-start" (click)="onChangeTheme(themeName)"> {{ themeName }}
                </li>
            }
        </ul>
</div>
    `
})

export class ChangeThemeComponent{
    currentTheme = signal<string | undefined | null>(localStorage.getItem('theme') || 'auto');
    document = inject(DOCUMENT);
    themeService = inject(ThemeService);
  

    onChangeTheme(themeValue: string): void {
        this.currentTheme.set(themeValue);
        localStorage.setItem('theme', themeValue);
        if (themeValue === 'auto') {
            this.themeService.themeStart();
        } else {
            this.document.documentElement.setAttribute('data-theme', themeValue);
        }
       
    }

}
