
      .logo {
        display: inline-block; /* Similar to inline elements like <span> */
        width: 3em; /* Width based on font size */
        height: 3em; /* Height based on font size */
        svg {
          width: 100%;
          height: 100%;
          fill: currentColor; /* Use currentColor to apply text color to SVG */
        }
      }
    