import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class SeoService {
  default = {
    title: 'LOSA AI - thư ký AI trực tuyến đáng yêu',
    description:
      'Giúp cuộc sống của bạn dễ dàng hơn với AI. AI Chat, AI tạo ảnh, AI viết giúp bạn tăng năng suất và hiệu quả làm việc',
    tags: '',
    image: '/frontend-assets/icon.webp',
    thumbnail: '/frontend-assets/icon.webp',
  };
  meta = inject(Meta);
  title = inject(Title);
  document = inject(DOCUMENT);

  constructor() {
    this.seoMeta({});
  }

  convertVietnameseToSlug(text: string) {
    text = text.toLowerCase();
    text = text.replace(/á|à|ả|ã|ạ|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    text = text.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    text = text.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    text = text.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    text = text.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    text = text.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    text = text.replace(/đ/gi, 'd');
    text = text.replace(/[^a-z0-9-\s]/g, '');
    text = text.replace(/(\s+)/g, '-');
    text = text.replace(/-+/g, '-');
    return text.trim();
  }

  async seoMeta(metatag: {
    ldJson?: boolean;
    url?: string;
    title?: string;
    description?: string;
    image?: string;
    thumbnail?: string;
    canonical?: string;
    tags?: string;
    createdAt?: string; // New parameter for creation date
    updatedAt?: string; // New parameter for update date
  }) {
    const url = metatag.url ?? this.document.location.href;
    const title =
      metatag.title ??
      this.document.querySelector('title')?.textContent ??
      this.default.title;
    const description =
      metatag.description ??
      this.document
        .querySelector('meta[name="description"]')
        ?.getAttribute('content') ??
      this.default.description;
    const image =
      metatag.image ??
      this.document
        .querySelector('meta[name="image"]')
        ?.getAttribute('content') ??
      this.default.image;
    const canonical = metatag.canonical ?? this.document.location.href;
    const fullPathImage = image.startsWith(this.document.location.origin) ? image : `${this.document.location.origin}/${image}`;
    const tags = metatag.tags ?? '';

    // Use provided createdAt and updatedAt, or fallback to defaults
    const createdAt = metatag.createdAt ?? "2023-10-01T12:00:00Z"; // Default creation date
    const updatedAt = metatag.updatedAt ?? "2023-10-02T14:30:00Z"; // Default update date

    const metaTags: MetaDefinition[] = [
      { property: 'og:url', content: url },
      { property: 'og:type', content: 'article' },
      { property: 'article:author', content: 'Losa Team' },
      { property: 'article:section', content: 'blog' },
      { property: 'article:tag', content: tags },
      { name: 'title', content: title },
      { property: 'og:title', content: title },
      { name: 'description', content: description.slice(0, 150) },
      { name: 'og:description', content: description.slice(0, 150) },
      { property: 'og:image:alt', content: title },
      { name: 'thumbnail', content: fullPathImage },
      { name: 'image', content: fullPathImage },
      { property: 'og:image', content: fullPathImage },
      { name: 'robots', content: 'index, follow' },
      { name: 'language', content: 'vi_VN' },
      { name: 'audience', content: 'general' },
      { property: 'og:locale', content: 'vi' },
    ];

    this.title.setTitle(title ?? '');
    this.setCanonicalLink(canonical);
    metaTags.filter((tag) => tag.content)
      .forEach((tag) => this.meta.updateTag(tag));
    // console.log('adding ldJsonScript', metatag.ldJson)
    if (metatag.ldJson) {
      this.setLdJson({
        headline: title,
        description: description,
        datePublished: createdAt, // Use createdAt for datePublished
        dateModified: updatedAt, // Use updatedAt for dateModified
        // author: {
        //   name: "John Doe",
        //   url: "https://example.com/author/johndoe"
        // },
        // image: [
        //   "https://example.com/photos/1x1/photo.jpg",
        //   "https://example.com/photos/4x3/photo.jpg",
        //   "https://example.com/photos/16x9/photo.jpg"
        // ]
      });
    }
  }


  // https://developers.google.com/search/docs/appearance/structured-data/article#json-ld

  setLdJson(data: {
    headline: string;
    description: string;
    datePublished?: string; // Make datePublished optional
    dateModified?: string; // Make dateModified optional
    author?: {  // Make author optional
      name: string;
      url?: string;
    };
    image?: string | string[];
    publisher?: {  // Make publisher optional
      name: string;
      logo?: {
        url: string;
      };
    };
  }): void {
    const ldJsonData: any = {
      "@context": "https://schema.org",
      "@type": "Article", // You can change this to "NewsArticle" or "BlogPosting" as needed
      "headline": data.headline,
      "description": data.description,
    };

    // Add datePublished if provided
    if (data.datePublished) {
      ldJsonData.datePublished = data.datePublished;
    }

    // Add dateModified if provided, otherwise fallback to datePublished
    if (data.dateModified) {
      ldJsonData.dateModified = data.dateModified;
    } else if (data.datePublished) {
      ldJsonData.dateModified = data.datePublished; // Fallback to datePublished if dateModified is not provided
    }

    // Add image if provided
    if (data.image) {
      ldJsonData.image = Array.isArray(data.image) ? data.image : [data.image]; // Ensure image is an array
    }

    // Add author if provided
    if (data.author) {
      ldJsonData.author = {
        "@type": "Person",
        "name": data.author.name,
        "url": data.author.url || "" // Optional author URL
      };
    }

    // Add publisher if provided
    if (data.publisher) {
      ldJsonData.publisher = {
        "@type": "Organization",
        "name": data.publisher.name,
        "logo": data.publisher.logo ? {
          "@type": "ImageObject",
          "url": data.publisher.logo.url
        } : undefined
      };
    }

    // Check if a JSON-LD script already exists
    let ldJsonScript = this.document.querySelector('script[type="application/ld+json"]') as HTMLScriptElement;
    if (ldJsonScript) {
      // Update the existing JSON-LD script
      ldJsonScript.textContent = JSON.stringify(ldJsonData);
    } else {
      // Create a new JSON-LD script
      ldJsonScript = this.document.createElement('script');
      ldJsonScript.setAttribute('type', 'application/ld+json');
      ldJsonScript.textContent = JSON.stringify(ldJsonData);
      this.document.head.appendChild(ldJsonScript);
    }
  }

  setCanonicalLink(url: string): void {
    // Check if a canonical link already exists
    let canonicalLink = this.document.querySelector('link[rel="canonical"]') as HTMLLinkElement;

    if (canonicalLink) {
      // Update the existing canonical link
      canonicalLink.setAttribute('href', url);
    } else {
      // Create a new canonical link
      canonicalLink = this.document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', url);
      this.document.head.appendChild(canonicalLink);
    }
  }
}
