import rehypeStringify from 'rehype-stringify';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { unified, Plugin } from 'unified';
import { Root as HastRoot } from 'hast'
import { Root as MdastRoot } from 'mdast'
import remarkGfm from 'remark-gfm';
import remarkMdx from 'remark-mdx';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import remarkFrontmatter from 'remark-frontmatter'
import rehypeParse from 'rehype-parse';
import rehypeRemark from 'rehype-remark';
import remarkStringify from 'remark-stringify';
import { visit } from 'unist-util-visit';
import rehypeExternalLinks from 'rehype-external-links'
import yaml from 'yaml';
import { Paragraph, Text, Heading, ThematicBreak } from 'mdast';

let alerted = false;
// ======
const supportsLookbehind = () => {
  try {
    new RegExp('(?<=a)b');
    return true;
  } catch (e) {
    // if (!alerted) alert('Trình duyệt không hỗ trợ định dạng bảng, hãy nâng cấp trình duyệt để trải nghiệm tốt nhất');
    // https://github.com/remarkjs/remark-gfm/issues/69
    // https://github.com/syntax-tree/mdast-util-gfm-autolink-literal/issues/10
    // https://github.com/syntax-tree/mdast-util-gfm-autolink-literal/issues/10#issuecomment-2384745741
    alerted = true;
    console.error('Trình duyệt không hỗ trợ định dạng bảng, hãy nâng cấp trình duyệt để trải nghiệm tốt nhất');
    return false;
  }
};

const empty: any = () => {
  return (tree: any) => {
    // plugin implementation for supportsLookbehind() === false
    return tree;
  };
};

// https://github.com/syntax-tree/mdast-util-gfm-autolink-literal/issues/10#issuecomment-2384745741
// we already add fixed package, so no need check
// ======
const toMarkdown = unified()
  .use(alignHtmlToMd)
  .use(rehypeParse)
  .use(rehypeKatex) // Add this line
  .use(rehypeRemark)
  .use(remarkMath) // Add this line
  .use(remarkGfm, { singleTilde: false })
  // https://github.com/syntax-tree/mdast?tab=readme-ov-file#thematicbreak
  .use(remarkStringify, {
    rule: '-',
  })
  .use(function () {
    return function (tree) {
      // console.dir('markdown tree', tree)
    }
  })

const toHtml = () => unified()
  .use(remarkParse, { listItem: true })
  .use(remarkFrontmatter, ['yaml', 'toml'])
  .use(remarkMath)  // Move this earlier
  .use(remarkGfm, { singleTilde: false })
  .use(alignMdToHTML)
  .use(remarkRehype)
  .use(rehypeKatex)  // Move this after remarkRehype
  .use(rehypeExternalLinks, { rel: ['nofollow'], target: '_blank' })
  .use(rehypeCustomIdHeading)
  .use(rehypeStringify);
  

const toHtmlForEditor = unified()
// .use(remarkFrontmatterEditor)
  .use(remarkParse, { listItem: true })
  .use(remarkFrontmatter, ['yaml', 'toml'])
  .use(remarkMath)  // Move this earlier
  .use(remarkGfm, { singleTilde: false })
  .use(alignMdToHTML)
  .use(remarkRehype)
  .use(rehypeKatex)  // Move this after remarkRehype
  .use(rehypeExternalLinks, { rel: ['nofollow'], target: '_blank' })
  .use(rehypeCustomIdHeading)
  .use(rehypeStringify);


export function htmlToMarkdownSerializeSync(content: string) {
  return toMarkdown.stringify(toMarkdown.runSync(toMarkdown.parse(content)));
}

export function markdownToHTMLParseSyncForEditor(content: string) {
  return toHtmlForEditor.stringify(toHtmlForEditor.runSync(toHtmlForEditor.parse(content)));
}

export function markdownToHTMLParseSync(content: string) {
  return toHtml().stringify(toHtml().runSync(toHtml().parse(content)));
}

export async function htmlToMarkdownSerialize(content: string) {
  return (await toMarkdown.process(content)).toString();
}

export async function markdownToHTMLParseForEditor(content: string) {
  return (await toHtmlForEditor.process(content)).toString();
}

export async function markdownToHTMLParse(content: string) {
  return (await toHtml().process(content)).toString();
}


function rehypeCustomIdHeading() {
  // Helper function to recursively get text from all child nodes
  function getText(node: any): string {
    if (node.type === 'text') {
      return node.value;
    } else if (node.children) {
      return node.children.map(getText).join('');
    }
    return '';
  }
  return (tree: any) => {
    visit(tree, 'element', (node: any) => {
      if (node.tagName.match(/^h[1-6]$/)) {
        // console.log('node.tagName', node.tagName);
        const text = getText(node);
        node.properties.id = text.replace(/\s+/g, '-').toLowerCase();
      }
    });
  };
}


export function remarkFrontmatterEditor() {
  return (tree: MdastRoot) => {
    const frontmatter = tree.children.find((node) => node.type === 'yaml');
    // console.log('tree and frontmatter', tree, frontmatter)
    if (frontmatter) {
      const data = yaml.parse(frontmatter.value) as Record<string, string>;
      // console.log('frontmatter data', data)
      const paragraphs: Paragraph[] = Object.keys(data).map((key) => {
        return {
          type: 'paragraph',
          children: [
            {
              type: 'text',
              value: `${key}: ${data[key]}`,
            },
          ],
        };
      });
      const hr: ThematicBreak = {
        type: 'thematicBreak',
      };
      return { ...tree, children: [hr, ...paragraphs, hr, ...tree.children.slice(1)], };
    }
    return tree; // return the original tree if no frontmatter is found
  };
};


//////////// THE REMARK ALIGN PLUGIN FOR MARKDOWN

export function alignMdToHTML() {
  return (tree: MdastRoot) => {
    visit(tree, (node, depth) => true, (node) => {
      if ('children' in node && node.children.length > 0 && node.children[0].type === 'text') {
        const textNode = node.children[0] as Text;
        const alignment = detectAlignmentBySymbol(textNode.value);
        if (alignment) {
          // console.warn('alignMdToHTML: have align', node)
          textNode.value = textNode.value
            .replaceAll(new RegExp(`${alignment.symbol}`, 'g'), '')
            .trim();
          node.data = node.data || {};
          node.data.hProperties = node.data.hProperties || {};
          node.data.hProperties['className'] = 'text-' + alignment.type;
          node.data.hProperties['style'] = 'text-align: ' + alignment.type;
        }
        // else {
        //   console.warn('alignMdToHTML: no align', node)
        // }
      }
    });
  };
}

export enum MarkAlignment {
  center = 'center',
  right = 'right',
  left = 'left',
  justify = 'justify',
}
// https://www.markdownguide.org/extended-syntax/#alignment
export enum MarkSymbol {
  CENTER = '-:-',
  RIGHT = '--:',
  LEFT = ':--',
  JUSTIFY = ':-:',
}

function detectAlignmentBySymbol(value: string) {
  if (value.includes(MarkSymbol.CENTER)) {
    return { type: MarkAlignment.center, symbol: MarkSymbol.CENTER };
  } else if (value.includes(MarkSymbol.RIGHT)) {
    return { type: MarkAlignment.right, symbol: MarkSymbol.RIGHT };
  } else if (value.includes(MarkSymbol.LEFT)) {
    return { type: MarkAlignment.left, symbol: MarkSymbol.LEFT };
  } else if (value.includes(MarkSymbol.JUSTIFY)) {
    return { type: MarkAlignment.justify, symbol: MarkSymbol.JUSTIFY };
  }
  return null;
}


///////////////////// HTML TO MARKDOWN

function alignHtmlToMd() {
  return (tree: HastRoot) => {
    visit(tree, (node, depth) => {
      // console.log('alignHtmlToMd node, depth', node, depth)
      return true
    }, (node, index, parent) => {
      // console.log('alignHtmlToMd', node, index, parent)
      const alignment = getAlignment(node as any);
      if (alignment) {
        const marker = getMarker(alignment);
        if (node.type === 'text') {
          node.value = `${marker} ${node.value}`;
        } else if (node.type === 'element') {
          // Handle nodes with children recursively
          if (node.children) {
            node.children.forEach((child) => {
              if (child.type === 'text') {
                child.value = `${marker} ${child.value}`;
              }
            });
          }
          // Handle node properties
          if (node.properties) {
            Object.keys(node.properties).forEach((key) => {
              if (typeof node.properties[key] === 'string') {
                node.properties[key] = `${marker} ${node.properties[key]}`;
              }
            });
          }
        }
      }
    });
  };
}


function getAlignment(node: { properties?: { style?: string }, attributes?: { [key: string]: string }, className?: string }): MarkAlignment | null {
  const className = node.className;
  const style = node.properties?.style;
  const attributes = node.attributes;

  if (className) {
    for (const alignment in MarkAlignment) {
      if (className.includes(`text-${alignment.toLowerCase()}`)) {
        return MarkAlignment[alignment as keyof typeof MarkAlignment];
      }
    }
  }

  if (style) {
    const textAlignMatch = style.match(/text-align:\s*(left|right|center|justify)/i);
    if (textAlignMatch) {
      return MarkAlignment[textAlignMatch[1].toLowerCase() as keyof typeof MarkAlignment];
    }
  }

  if (attributes) {
    const textAlign = attributes['text-align'];
    if (textAlign) {
      return MarkAlignment[textAlign.toLowerCase() as keyof typeof MarkAlignment];
    }
  }

  return null;
}

function getAlignmentSymbol(alignment: MarkAlignment): string {
  switch (alignment) {
    case MarkAlignment.center:
      return MarkSymbol.CENTER;
    case MarkAlignment.right:
      return MarkSymbol.RIGHT;
    case MarkAlignment.left:
      return MarkSymbol.LEFT;
    case MarkAlignment.justify:
      return MarkSymbol.JUSTIFY;
  }
}

function getMarker(alignment: MarkAlignment): string {
  return getAlignmentSymbol(alignment);
}

const case_center = `# -:- You are my angel`;
const case_right = `--: *this is begining of everything*`;
const case_left = `:-- *this is begining of everything*`;
const case_justify = `:-: *this is begining of everything*`;
const case_default = `*this is begining of everything*`;

// https://github.com/remarkjs/react-markdown/issues/278#issuecomment-1765161662
// function markdownMultiBreakPlugin() {
//   return {
//     name: 'markdown-multi-break',
//     transform(code, id) {
//       if (id.endsWith('.md')) {
//         let md = code;

//         // Preserve code blocks
//         md = md.replace(/```[\s\S]*?```/g, (m) =>
//           m.replace(/\n/g, '\n ')
//         );

//         // Support multiple linebreaks
//         md = md.replace(/(?<=\n\n)(?![*-])\n/g, '&nbsp;\n ');

//         // Support single linebreak
//         md = md.replace(/(\n)/gm, '  \n');

//         return {
//           code: md,
//           map: null
//         };
//       }
//     }
//   };
// }

// export default markdownMultiBreakPlugin;