import { ChatMessage } from "configs/global";

export interface TokenUsage {
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
}
export interface ChatMessageFull {
  messageId: string;
  role: string;
  senderUserId: string;
  text: string;
  messageCreatedAt: string;
  reasoning?: string;
  search_web?: {
    tool_name: string;
    tool_call: any;
    tool_result: any;
  }
  attachment_contents?: ChatMessage['attachment_contents'];
  content?: any[];
  usage?: {
    total_tokens: number;
    prompt_tokens: number;
    completion_tokens: number;
  };
  editing?: boolean;
}
export interface StreamingMessage {
  text: string;
  reasoning: string;
  attachment_contents?: ChatMessage['attachment_contents'];
  inline_image?: {
    mimeType: string;
    data: string;
  }
  search_web?: {
    tool_name: string;
    tool_call: any;
    tool_result: any;
  }
}
export interface AIStreamResponse extends StreamingMessage {
  usage: TokenUsage;
  subscription: any;

}

export interface LineType { isFirst: boolean, isReason: boolean };

export function newTokenUsage() {
  return { prompt_tokens: 0, completion_tokens: 0, total_tokens: 0 };
}

export async function fakeAiStreaming(
  settings: { abortController?: AbortController, messages: ChatMessage[] },
  cb: (streamingMessage: StreamingMessage) => void,
) {
  const userMessages = settings.messages.filter(m => m.role === 'user');
  const lastMessage = userMessages[userMessages.length - 1];
  if (lastMessage.text.length === 0) {
    console.warn('lastMessage.text is empty!!', lastMessage.text);
  }
  lastMessage.text = lastMessage.text.length
    ? lastMessage.text + 'Lao động nữ sinh con được nghỉ việc hưởng chế độ thai sản trước và sau khi sinh con tổng là 6 tháng. Trường hợp lao động nữ sinh đôi trở lên thì tính từ con'
    : `\n
# **Giới thiệu**\n
this is a fake AI ~world~\n
## world\n
this is new data
`;

  let result: AIStreamResponse = {
    usage: {
      prompt_tokens: 0,
      completion_tokens: 0,
      total_tokens: 0,
    },
    subscription: {},
    text: '',
    reasoning: '',
  };

  const listChunk = ['Fake AI:\n'].concat(lastMessage.text.split(''));
  let streamingMessage: StreamingMessage = {
    text: '',
    reasoning: '',
    search_web: undefined
  }

  const tokenPerSecond = 30;
  const charactersPerToken = 4;
  const milisecondPerChar = 1000 / (tokenPerSecond * charactersPerToken); // use to be 100ms

  for (let i = 0; i < listChunk.length;) {
    await new Promise((resolve) => setTimeout(resolve, milisecondPerChar));
    const delta = listChunk[i];
    // console.log('delta', delta);
    streamingMessage.text += delta;
    streamingMessage.reasoning += delta;
    cb(streamingMessage);


    i++;

    // console.log('settings.abortController', settings.abortController)
    try {
      if (settings.abortController?.signal.aborted) {
        new DOMException('AbortError', 'The user aborted a request.');
        break;
      }
    } catch (error) {
      console.error('forgot abort?');
    }
  }

  result.text = streamingMessage.text;
  result.reasoning = streamingMessage.reasoning;
  return result;
}



export async function aiStreaming(
  settings: {
    subscription_id: string;
    model_id: string;
    character_id?: string | null;
    api?: string;
    abortController?: AbortController;
    rankedOnlyResults?: any;
    temperature?: number;
    task?: 'chat' | 'completion';
    systemPrompt?: string;
    max_tokens?: number;
    haveBrowser?: boolean
    messages?: ChatMessage[]
  },
  cb: (streamingMessage: StreamingMessage) => void,
) {
  const body = JSON.stringify({
    subscription_id: settings.subscription_id,
    model_id: settings.model_id,
    character_id: settings.character_id,
    context: settings.rankedOnlyResults,
    temperature: settings.temperature,
    task: settings.task ?? 'chat',
    systemPrompt: settings.systemPrompt ?? '',
    max_tokens: settings.max_tokens,
    messages: settings.messages,
    haveBrowser: settings.haveBrowser,
  });
  // console.log('your body', body);

  let result: AIStreamResponse = {
    usage: {
      prompt_tokens: 0,
      completion_tokens: 0,
      total_tokens: 0,
    },
    subscription: {},
    text: '',
    reasoning: '',
    search_web: undefined,
    inline_image: undefined,
    attachment_contents: undefined,

  };

  await handleStream(settings, body, (lines_text: string, value: string) => {
    let streamingMessage: StreamingMessage = {
      text: '',
      reasoning: '',
      search_web: undefined,
      inline_image: undefined,
      attachment_contents: undefined,

    }
    let isInThinkBlock = false;

    const lines = lines_text.split('\n').filter((line) => line.trim() !== '');

    for (const line of lines) {
      let choice: {
        delta: {
          content: string | undefined;
          reasoning_content: string | undefined;
          inline_image: { mimeType: string, data: string } | undefined;
        }
      } = { delta: { content: undefined, reasoning_content: undefined, inline_image: undefined } };

      try {
        const parsed = JSON.parse(line);
        const isAnthropicChats = parsed.type === 'content_block_delta';
        const openAIChoice = parsed.choices?.[0];
        const googleCandidate = parsed.candidates?.[0];
        if (isAnthropicChats) {
          // console.log('isAnthorpic')
          if (parsed.delta.type === 'thinking_delta') {
            choice.delta.reasoning_content = parsed.delta.thinking;
          } else {
            choice.delta.content = parsed.delta.text;
          }
        } else if (googleCandidate) {
          if (googleCandidate.finishReason) {
            if (googleCandidate.finishReason !== 'STOP') {
              throw new Error('finishReason: ' + googleCandidate.finishReason);
            }
          }
          if (googleCandidate.content) {
            if (googleCandidate.content.parts?.[0]?.text) {
              choice.delta.content = googleCandidate.content.parts?.[0]?.text;
            } else if (googleCandidate.content.parts?.[0]?.inlineData) {
              if (googleCandidate.content.parts?.[0]?.inlineData?.mimeType.startsWith('image/')) {
                choice.delta.inline_image = googleCandidate.content.parts?.[0]?.inlineData;
              }
            }
          }
        } else if (openAIChoice) {
          // console.log('isOpenAIChoice')
          // choice = parsed.choices[0];
          choice.delta.content = parsed.choices[0].delta.content;
        }

        // Handle the content processing
        if (choice?.delta.reasoning_content) {
          streamingMessage.reasoning += choice.delta.reasoning_content;
        } else if (choice.delta.content) {
          const content = choice.delta.content;
          // manual thinking block handling for openai
          if (content.includes('<think>')) {
            isInThinkBlock = true;
            // Create new event for starting think block

          } else if (content.includes('</think>')) {
            isInThinkBlock = false;
            // Create new event for ending think block
          } else if (isInThinkBlock) {
            // Stream thinking content immediately
            streamingMessage.reasoning += content;

          } else {
            // Stream regular content immediately
            streamingMessage.text += content;

          }
        } else if (choice.delta.inline_image) {
          console.log('your image here', choice.delta.inline_image)
          streamingMessage.inline_image = choice.delta.inline_image;
        } else {
          console.log('your unknown choice?', choice)
        }

        if (parsed.usage)
          result.usage = parsed.usage;
        if (parsed.subscription)
          result.subscription = parsed.subscription as { subscription_balance: number; subscription_amount: number; };

        if (parsed.tool_name === 'search_web') {
          streamingMessage.search_web = {
            tool_name: parsed.tool_name,
            tool_call: parsed.tool_call,
            tool_result: parsed.tool_result,
          }
          if (typeof streamingMessage.search_web.tool_result?.content === 'string') {
            streamingMessage.search_web.tool_result.content = JSON.parse(streamingMessage.search_web.tool_result.content);
          }
          result.search_web = streamingMessage.search_web;
        }
        if (parsed.error || parsed.status === 'error') {
          const error = new Error(JSON.stringify(parsed));
          console.error('parse failed: ', error);
          console.error('parse line: ', `${line}`, choice);
          throw error;
        }

      } catch (error) {
        if (error instanceof SyntaxError) {
          continue;
        } else {
          console.error('parse failed: ', error);
          console.error('parse line: ', `${line}`, choice);
          throw error;
        }
      }

      // Only call callback if we have content to stream
      if (streamingMessage) {
        cb(streamingMessage);
      }
    }

    result = { ...result, ...streamingMessage }
  });
  return result;
}

async function handleStream(
  { api, abortController }: { api?: string; abortController?: AbortController },
  body: string,
  callback: (result_text: string, value: string) => void,
) {
  abortController = abortController ?? new AbortController();
  api = api ?? '/v1/api/chat';
  const response = await fetch(api, {
    method: 'POST',
    body: body,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    },
    signal: abortController.signal,
  });
  // Check if the request was successful
  if (!response.ok) {
    // Throw an error if the server response was not ok
    // Check if the response is JSON or text
    const contentType = response.headers.get('content-type');
    let errorData;

    if (contentType && contentType.includes('application/json')) {
      errorData = await response.json();
      throw new Error(errorData.message, { cause: errorData });
    } else {
      errorData = await response.text();
      throw new Error(errorData, { cause: errorData });
    }

  }
  const decoderTransformStream = new TextDecoderStream();
  const reader = response.body!.pipeThrough(decoderTransformStream).getReader();
  await textDecoderStream(reader, callback);
}

async function textDecoderStream(
  reader: ReadableStreamDefaultReader,
  callback: (result_text: string, value: string) => void,
) {
  let total = '';
  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    // Using reader encode
    // const decodedValue = decoder.decode(chunk);
    // using decode stream
    switch (value) {
      case 'ERROR:rate_limit_exceeded':
        total = 'Lỗi quá lượt truy cập. Rate Limit Exceeded';
        break;
      case 'ERROR:internal_server_error':
        total = 'Lỗi server. Internal Server Error';
        break;
      case 'ERROR:token_limit_reached':
        total = 'Lỗi token đạt giới hạn tối đa. Token Limit Reached';
        break;
      case 'ERROR:api_error':
        total = 'Lỗi API khi trả lời. API Error';
        break;
      case 'ERROR:unknown':
        total = 'Lỗi không xác định. unknown Error';
        break;
      default:
        total += value;
    }
    callback(total, value);
  }
}

export type AIStreamingStatus =
  | 'idle'
  | 'connecting'
  | 'streaming'
  | 'error'
  | 'result';
