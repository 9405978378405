import { Injectable, afterNextRender, computed, inject, signal } from '@angular/core';
import { ChatMessage, DropdownItemBase } from 'configs/global';
import { SubscriptionGear } from 'frontend-state/src/people';
import { modelList } from 'configs/src/models/model-list';
import { FormControl } from '@angular/forms';
import { ChatMessageFull, StreamingMessage } from 'frontend-ui/src/chatter-dashboard/pages/ai-client';
import { nanoid } from 'nanoid';

// NOTE: polyfill
if (!Object.groupBy) {
    Object.groupBy = function <T, K extends PropertyKey>(array: T[], callback: (item: T, index: number) => K) {
        return Array.from(array).reduce((result: Record<K, T[]>, item, index) => {
            const key = callback(item, index);
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push(item); // Add the item to the corresponding group
            return result; // Explicitly return the result object
        }, {} as Record<K, T[]>);
    }
}

@Injectable({ providedIn: 'root' })
export class ModelGear {
    defaultModelId = modelList[0].model_id;
    currentModelProductId = signal<string | null>(null);
    subscriptionGear = inject(SubscriptionGear);
    aiModelInformation = computed(() => {
        const model = modelList
            .find(m => m.model_id === this.currentModelProductId()) ?? modelList[0];
        return model;
    })

    ui = signal({
        status: '[idle]',
        conversationId: nanoid(),
        streamingMessageId: nanoid(),
        userMessageId: nanoid(),
        dialogs: [] as ChatMessageFull[],
        streamingMessage: {} as StreamingMessage | object,
        userMessage: {
            role: 'user',
            text: new FormControl<string>(''),
            reason: '',
            attachment_contents: [] as ChatMessage['attachment_contents'],
            search_web: false
        },
    });



    modelList = computed<Record<string, DropdownItemBase[] | undefined>>(() => {
        const list = modelList.map(m => ({
            id: m.model_id,
            title: m.model_display_name,
            // description: ` ${m.model_price}đ/token. ${m.model_description}`,
            description: `${m.model_description}`,
            image: m.model_image,
            // description: ` ${Math.round(m.model_price * 1024)}đ/tin. ${m.model_description}`,
            group_id: m.model_rank,
            group: {
                title: this.rankToString(m.model_rank),
                description: this.rankToString(m.model_rank),
                groupIndex: m.model_rank,
            },
            // disabled: this.subscriptionGear.mySubscription()?.subscriptionType === 'free' && m.model_rank > 2
            disabled: false
        } as DropdownItemBase));

        const groupItems = Object.groupBy(list, item => item.group_id!);
        return groupItems;
    });

    constructor() {
        afterNextRender(() => { this.recoveryPreviousModel(); })
    }


    rankToString(model_rank: number) {
        switch (model_rank) {
            case 1:
                return 'sơ cấp'
            case 2:
                return 'trung cấp'
            case 3:
                return 'thượng cấp'
            case 4:
                return 'suy luận'
            default:
                return '';
        }
    }

    autoSaveModelIdSelection() {
        const modelProductId = this.currentModelProductId();
        if (modelProductId) {
            console.log('model changes', modelProductId);
            localStorage.setItem('modelProductId', modelProductId);
        }
    }

    recoveryPreviousModel() {
        const modelProductIdPreviously = localStorage.getItem('modelProductId');
        if (modelProductIdPreviously?.length) {
            const modelInDatabase = modelList.find(m => m.model_id === modelProductIdPreviously);
            if (modelInDatabase) {
                this.currentModelProductId.set(modelInDatabase.model_id);
            } else {
                this.currentModelProductId.set(this.defaultModelId);
                localStorage.removeItem('modelProductId');
            }
        } else {
            this.currentModelProductId.set(this.defaultModelId);
        }
    }

    changeModel(id: string) {
        this.currentModelProductId.set(id);
        if (this.aiModelInformation().model_rank === 4) { 
            this.ui.update(ui => {
                return {
                    ...ui,
                    userMessage: {
                        ...ui.userMessage, 
                        search_web: false
                    }
                }
            });
        }
    }

}