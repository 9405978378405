import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  ChangeDetectorRef,
  input,
  inject,
  afterNextRender,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  distinctUntilChanged,
  fromEvent,
  map,
  tap,
  pairwise,
  filter,
  throttleTime,
  startWith,
  bufferCount,
} from 'rxjs';
@UntilDestroy()
@Directive({
  selector: '[displayOnScroll]',
  standalone: true,
})
export class DisplayOnScrollDirective {
  // https://github.com/angular/angular/issues/55738
  displayOnScroll = input<string | undefined>(undefined);
  private hasView = false;
  hiddenClass = ['hidden', 'invisible'];
  document = inject(DOCUMENT);
  elementRef = inject(ElementRef);
  cd = inject(ChangeDetectorRef);
  debug = false;

  constructor() {
    afterNextRender(() => {
      console.warn('we created DisplayOnScrollDirective only on browser');
      this.startWatching();
    })
  }

  startWatching() {
    const element = this.elementRef.nativeElement as HTMLElement;
    this.initElementStyles(element);

    const SCROLL_SENSITIVITY = 10; // Reduced threshold for higher sensitivity
    const THROTTLE_TIME = 50; // Time in milliseconds to throttle scroll events

    fromEvent(this.document, 'scroll', { capture: true })
      .pipe(
        map(() => window.scrollY),
        pairwise(), // Emit the previous and current scroll positions as a pair
        map(([prevScrollY, currScrollY]) => currScrollY - prevScrollY), // Calculate the scroll offset
        filter(offset => Math.abs(offset) > SCROLL_SENSITIVITY), // Filter out small scrolls
        tap(offset => this.debug ? console.log('offset', offset) : null),
        throttleTime(THROTTLE_TIME), // Throttle the scroll events to prevent rapid toggling
        map(offset => offset < 0), // Determine the scroll direction (true if scrolling up, false if scrolling down)
        startWith(true), // Emit initial scroll is visbile since the user may not have scrolled yet
        distinctUntilChanged(), // Only emit if the direction has changed
        tap((shouldShow) => console.log('shouldShow:', shouldShow)), // Log the scroll direction
        untilDestroyed(this),
      )
      .subscribe((shouldShow) => this.handleScroll(shouldShow as boolean, element)); // Handle the scroll event
  }

  private initElementStyles(element: HTMLElement) {
    // element.classList.add('transition-all', 'duration-300', 'ease-in-out'); // Add initial styles for smooth transitions
    element.classList.add('animate-none'); // Add initial styles for smooth transitions
  }

  private handleScroll(shouldShow: boolean, element: HTMLElement) {
    console.log('handleScroll shouldShow:', shouldShow, 'should toogle', shouldShow !== this.hasView)
    if (shouldShow !== this.hasView) {
      this.toggleElementVisibility(shouldShow, element); // Toggle visibility if the state has changed
    }
  }

  private toggleElementVisibility(shouldShow: boolean, element: HTMLElement) {
    this.hasView = shouldShow;
    if (shouldShow) {
      console.log('show now!');
      element.classList.remove(...this.hiddenClass); // Show the element
    } else {
      console.log('hidden');
      element.classList.add(...this.hiddenClass); // Hide the element
    }
  }
  
}
