import { Injectable, computed, inject, signal } from '@angular/core';
import { DialogService } from '@ngneat/dialog';
import { PeopleGear } from './people.gear';
import { DialogsPeople, EndTrialDialog, WelcomeTrialDialog } from '@app/frontend-ui';
import { SnackbarService, graphQLClient } from '@app/frontend-core';
import { injectQuery, injectMutation } from '@tanstack/angular-query-experimental';
import { MySubscriptionDocument } from '@app/generated';


@Injectable({ providedIn: 'root' })
export class SubscriptionGear {
    peopleGear = inject(PeopleGear);
    dialogsPeople = inject(DialogsPeople);
    dialogService = inject(DialogService);
    snackbarService = inject(SnackbarService);
    activeSubscriptionId = signal('');

    mySubscriptionQuery = injectQuery(() => ({
        queryKey: ['mySubscription'],
        queryFn: async ({ queryKey }) => {
            const { appProductMySubscription } = await graphQLClient.request(
                MySubscriptionDocument,
            );
            // this.checkSubscription(result);
            // const sub = null;
            const sub = appProductMySubscription?.nodes[0];
            if (sub) {
                this.mySubscription.set(sub);
                this.activeSubscriptionId.set(sub.subscriptionId!);
                return sub;
            } else {
                return this.claimFree();
            }
        },
        enabled: !!this.peopleGear.myProfile()?.userId,
    }));

    async claimFree() {
        if (this.peopleGear.myProfile()?.userId) {
            const response = await fetch('/v1/api/claim-free', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });

            const result = await response.json();
            if (result.status === 'success') { 
                this.snackbarService.showSuccess("Đã kích hoạt gói miễn phí")
                setTimeout(() => {
                    this.mySubscriptionQuery.refetch();
                }, 1000);
            } else {
                this.snackbarService.showError("Không thể kích hoạt gói miễn phí")
            }

            return response.json();
        } else {
            return null;
        }

    }

    mySubscription = signal<any | null>(null);


    

    async activateTrial() {
        this.openWelcomeTrialDialog();

    }

    openWelcomeTrialDialog() {
        const dialog = this.dialogService.open(WelcomeTrialDialog);
    }
    openEndTrialDialog() {
        const dialog = this.dialogService.open(EndTrialDialog);
    }


}