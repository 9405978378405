import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { ActionEvent, toggleElementOnClick } from '@app/frontend-core';
import { PeopleGear, SubscriptionGear } from '@app/frontend-state';
import { LayoutGrid, LogOut, LucideAngularModule, Package, Store } from "lucide-angular"
import { ChangeThemeComponent } from 'frontend-ui/src/components/change-theme.component';
import { DialogRef } from '@ngneat/dialog';
import { DialogsPeople } from '../people';

@Component({
    selector: 'user-dialog',
    imports: [
        CommonModule,
        LucideAngularModule,
        ChangeThemeComponent
    ],
    template: `


  `,
    styles: [
        `
      :host {
        display: block;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDialog {
  
}
