import { CommonModule } from '@angular/common';
import { Component, input, Type, TemplateRef, Signal } from '@angular/core';
import { ActionEvent } from '@app/frontend-core';
import { ChevronDown, ChevronRight, LucideAngularModule, Plus } from 'lucide-angular';

export interface CollapseMenu {
    id: string;
    name: string;
    opened: boolean;
    templateRef?: Signal<TemplateRef<any> | undefined>;
    templateContext?: any;
    component?: Type<any> | null;
    componentInputs?: Record<string, any>;
    actions: {
        type: string;
        name: string;
        icon: any;
        trigger: () => any;
    }[];
}

@Component({
    imports: [CommonModule, LucideAngularModule],
    selector: 'app-collapse-menus',
    template: `
    <div class="flex flex-col items-start h-full w-full">
    @for (tabItem of collapseMenus(); track $index) {
    <div tabindex="0" class=" relative collapse min-w-0 w-full max-w-full my-0 transition-none animate-none rounded-none border-b border-base-content/20"
        [ngClass]="[(tabItem.opened ? 'collapse-open min-h-[16%] flex-1' : 'collapse-close h-fit'), ($first && collapseMenus().length > 1 ? 'max-h-fit' : 'max-h-full')]">
        <!-- NOTE: h-full help show dropdown menu -->
        <input class="min-h-fit" title="chọn hội thoại" type="checkbox" (change)="tabItem.opened = !tabItem.opened" />
        <div class="bg-base-300 collapse-title p-0 flex items-center justify-between min-h-8 overflow-hidden">
           <span class="font-bold truncate flex self-center">
                <lucide-icon class="inline-flex" [name]="!tabItem.opened ? icons.ChevronRight : icons.ChevronDown"></lucide-icon>
                {{ tabItem.name.length ? tabItem.name : 'Losa.vn' }}
            </span>
            <div class="flex">
                @for (menuAction of tabItem.actions; track $index) {
                    <button class="btn btn-ghost btn-xs cursor-pointer hover:bg-primary hover:text-primary-content z-[1]"
                    [title]="menuAction.name"  (click)="menuAction.trigger()">
                        <lucide-icon [name]="menuAction.icon"></lucide-icon>
                    </button>
                }
            </div>
        </div>
        
        
        <div class="menu flex-nowrap flex-grow h-full w-auto overflow-y-auto overflow-x-hidden p-0">
            @if(tabItem.templateRef?.(); as templateRef) {
                <ng-container [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="tabItem.templateContext"></ng-container>
            }
            @else if(tabItem.component) {
                <ng-container [ngComponentOutlet]="tabItem.component" [ngComponentOutletInputs]="tabItem.componentInputs"></ng-container>
            } @else {
                <pre> warning: no template or component!!! {{ tabItem | json }} </pre>
            }
        </div>
    </div>
    }

</div>

    `
})

export class CollapseMenusComponent {
    example: CollapseMenu = {
        id: 'conversations',
        name: 'Hội thoại',
        opened: true,
        actions: [{
            type: 'new-conversation',
            name: 'mới',
            icon: Plus,
            trigger: () => { },
        }],
        templateRef: undefined
    };
    collapseMenus = input<CollapseMenu[]>([]);
    icons = { ChevronRight, ChevronDown };

    action(event: ActionEvent) {

    }
}