
import { Clover, File, FilePen, ShieldPlus, MessageCircle,  User, Users, Boxes, Newspaper, Terminal } from 'lucide-angular';
import { DropdownItemBase } from '../../global';
import { Type } from '@angular/core';
import { UserDialog } from 'frontend-ui/src/layout/user.dialog';
export interface DataMenuInterface extends DropdownItemBase {
  routerId: string;
  linkTo?: string;
  isActive?: boolean;
  type?: 'modifer' | 'navigation' | 'tab';
  dialog?: Type<any>;
  customClass?: string;
}

export const DATA_NAVIGATION_MENU: DataMenuInterface[] = [

]

export const DATA_MODIFER_MENU: DataMenuInterface[] = [
  {
    routerId: 'double-editor',
    title: 's.thảo đôi',
    description: '',
    linkTo: '/editor',
    icon: Clover,
    type: 'modifer',
  },
  {
    routerId: 'bottom-streaming-result',
    title: 'Bảng phụ',
    description: '',
    linkTo: '/editor',
    icon: Clover,
    type: 'modifer',
  },

]

export const MAIN_SIDEBAR: DataMenuInterface[] = [

  {
    routerId: 'chat',
    linkTo: '/chat',
    title: 'Trò chuyện',
    icon: MessageCircle,
    description: '',
  },
  {
    routerId: 'character',
    linkTo: '/character',
    title: 'Nhân vật',
    icon: Users,
    description: '',
  },
  {
    routerId: 'editor',
    linkTo: '/editor',
    title: 'Soạn thảo',
    icon: FilePen,
    description: '',
  },
  {
    routerId: 'learn',
    linkTo: '/learn',
    title: 'Học',
    icon: Boxes,
    description: '',
  },
  {
    routerId: 'prompt',
    linkTo: '/prompt',
    title: 'Prompt',
    icon: Terminal,
    description: '',
  },
]

export const BOTTOM_SIDEBAR: DataMenuInterface[] = [
  {
    routerId: 'account',
    linkTo: '/account',
    title: 'Tài khoản',
    icon: User,
    description: '',
  },
  {
    routerId: 'pricing',
    linkTo: '/pricing',
    title: 'Nâng cấp (giảm 80%)',
    icon: ShieldPlus,
    description: '',
  },
  {
    routerId: 'blog',
    linkTo: '/blog',
    title: 'Blog',
    icon: Newspaper,
    description: '',
  },


]

// export const DATA_MENU_SETTING: DataMenuInterface[] = [
//   {
//     routerId: 'organization',
//     title: 'Tổ chức',
//     icon: faSitemap,
//     linkTo: '/settings/organization'
//   },
//   {
//     routerId: 'shop',
//     title: 'Cửa hàng',
//     icon: faStore,
//     linkTo: '/settings/shop'
//   },
//   {
//     routerId: 'staff',
//     title: 'Nhân viên',
//     icon: faUsersGear,
//     linkTo: '/settings/staff'
//   },
//   {
//     routerId: 'user',
//     title: 'Người dùng',
//     icon: faUser,
//     linkTo: '/settings/user'
//   },
// ]
